import React, { Component, Fragment } from 'react';
import {
  Link,
  NavLink,
  Redirect,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import kali from 'kali';

import 'react-datepicker/dist/react-datepicker.css';

import {
  getNavData,
  getSectionNavData,
  getSectionAjaxRoute,
} from '../../data/navData.js';

import {
  getSectionFields,
} from '../../data/fieldData.js';

import List, { deleteRecord } from '../../list/List.js';

import Form, {
  buildSelectData,
  getFormFieldsStatus,
  getFormFieldChanges,
} from '../../form/Form.js';

import { formatDateShort } from '../../form/field/Date.js';

const API_PREFIX = process.env.REACT_APP_API_PREFIX;
const CACHE_CONTENT_DATA = false;
const CACHE_SECTION_DATA = false;

// Get IE or Edge browser version
let version = detectIE();

let internetExplorer = false;

if (version === false) {
  console.log('Browser is Not IE or Edge')
} else if (version >= 12) {
  console.log('Browser is Edge')
} else {
  console.log('Browser is Internet Explorer')
  internetExplorer = true
}

function detectIE() {
  let ua = window.navigator.userAgent;

  let msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  let trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    let rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  let edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      section: this.props.portalSection,
      contentData: {},
      sectionData: [],
      editIndex: -1,
      inline: false,
      modal: false,
      specialInstructionsHidden: true,
      usersData: this.props.usersData || {},
      imgUpload: {},
      expired: false,
    };

    this.cache = {
      contentData: {},
      sectionData: [],
    };

    this.section = this.props.portal.props.section;
    this.companies = false;
    this.buildings = false;
  }

  componentDidMount() {
    this.fetchDashboardContent(this.props.selectedProperty);
    this.fetchDashboardContentSection(this.props.selectedProperty, this.props.portal.props.section);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.usersData !== this.props.usersData) {
      this.setState({
        usersData: this.props.usersData,
      });

      return;
    }

    let { portal } = this.props;
    let { section } = portal.props;

    if (this.state.editIndex === -1) {
      let updateEditIndex = false;
      if (section === 'buildingInfo') {
        updateEditIndex = true;
      }

      if (section === 'pdfUpload') {
        updateEditIndex = true;
      }

      if (updateEditIndex) {
        this.setState({
          editIndex: 0,
        });

        return;
      }
    }

    let { selectedProperty: prevSelectedProperty } = prevProps;
    let { selectedProperty } = this.props;
    if (prevSelectedProperty !== selectedProperty) {
      // clear cache on property change
      this.cache.sectionData = [];

      this.fetchDashboardContent(selectedProperty, () => {
        let navData = getNavData(this.state.contentData);

        let sectionIsEnabled = false;
        let firstEnabledSection;
        for (var i in navData) {
          if (navData[i] && navData[i].route) {
            if (!firstEnabledSection) {
              firstEnabledSection = navData[i];
            }

            if (navData[i].route === section) {
              sectionIsEnabled = true;
              break;
            }
          }
        }

        // keep the section if we can
        if (!sectionIsEnabled) {
          // HACK: redirect wasn't work with state stuff
          let l = window.location;
          return window.location.href = `${l.protocol}//${l.host}/dashboard/content/${firstEnabledSection.route}`;
        }
        this.fetchDashboardContentSection(selectedProperty, section);
      });

      return;
    }

    if (prevProps.portalSection !== this.props.portalSection) {
      this.setState({
        section: this.props.portalSection,
        modal: false,
        inline: false,
      });
    }

    if (this.section !== section) {
      window.ignorePendingChanges = false;

      this.section = section;
      this.fetchDashboardContentSection(selectedProperty, section);
    }
  }

  setContentData(data, selectedProperty, cb = false) {
    if (CACHE_CONTENT_DATA) {
      this.cache.contentData = Object.assign({}, this.cache.contentData, {
        [selectedProperty]: data,
      });
    }

    this.setState({
      contentData: data,
    }, () => {
      if (typeof cb === 'function') {
        return cb(data);
      }
    });
  }

  setContentSectionData(data, selectedProperty, section, cb = false) {
    if (CACHE_SECTION_DATA) {
      this.cache.sectionData = Object.assign({}, this.cache.sectionData, {
        [selectedProperty]: Object.assign({}, this.cache.sectionData[selectedProperty] || {}, {
          [section]: data.data,
        }),
      });
    }

    // make sure companies reflects 
    // what was last created/updated
    if (section === 'companies') {
      this.companies = data;
    }

    this.setState({
      searchDataFilter: '',
      sectionDataFiltered: false,
      filteredSectionData: [],
      sectionData: data,
    }, () => {
      if (typeof cb === 'function') {
        return cb(data);
      }
    });
  }

  fetchDashboardContent(selectedProperty, cb = false) {
    if (!selectedProperty) {
      return;
    }
    console.log('FETCH_CONTENT:', selectedProperty);

    if (CACHE_CONTENT_DATA) {
      try {
        let cachedContentData = this.cache.contentData[selectedProperty];
        if (cachedContentData) {
          return this.setState({
            contentData: cachedContentData,
          });
        }
      } catch (err) {
        // console.warn(err.message);
      }
    }

    // this.props.portal.setState({
    //   loading: true,
    // }, () => {
    let { url } = this.props.app.signBroncoURL(`https:${API_PREFIX}/legacy/updateContentByProp/${selectedProperty}/`);
    new kali().get(url, {
      success: (_kali, res, data) => {
        if (!data.data) {
          data.data = [];
        }

        this.setContentData(data.data[0], selectedProperty, () => {
          // assume cb handles turning off loading
          if (typeof cb !== 'function') {
            // NOTE: should section data always close out loading?
            return;

            // this.props.portal.setState({
            //   // loading: false,
            // });
          }
        });

        if (typeof cb === 'function') {
          return cb(data);
        }
      },

      failure: (_kali, err) => {
        console.error(err.statusText, err);
        this.props.portal.setState({
          // loading: false,
        });
        toast.error(err.statusText, {
          position: toast.POSITION.TOP_RIGHT
        });
      },

      notAuthorized: (_kali, res, data) => {
        if (data.error) {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }

        this.props.app.logout();
      }
    });
  }

  fetchDashboardContentSection(selectedProperty, section, cb = false) {
    if (!selectedProperty || !section) {
      return;
    }

    if (CACHE_SECTION_DATA) {
      try {
        let cachedSectionData = this.cache.sectionData[selectedProperty][section];
        if (cachedSectionData) {
          return this.setState({
            sectionData: cachedSectionData,
          });
        }
      } catch (err) {
        // console.warn(err.message);
      }
    }

    if (!this.props.portal.state.loading) {
      this.props.portal.setState({
        loading: true,
      }, () => {
        this.fetchDashboardContentSection(selectedProperty, section, cb);
      });

      return;
    }

    if (!this.buildings) {
      this.buildings = true;
      this.fetchDashboardContentSection(selectedProperty, 'buildings', (data) => {
        this.buildings = data.data;

        this.fetchDashboardContentSection(selectedProperty, section, cb);
      });

      // console.log(section);
      return;
    }

    if (section === 'individuals') {
      if (!this.companies) {
        this.companies = true; // don't loop forever!
        this.fetchDashboardContentSection(selectedProperty, 'companies', (data) => {
          this.companies = data.data;

          this.fetchDashboardContentSection(selectedProperty, section, cb);
        });

        // console.log(section);
        return;
      }
    }

    console.log('FETCH_SECTION:', section, selectedProperty);
    let ajaxRoute = getSectionAjaxRoute(section);
    let { url } = this.props.app.signBroncoURL(`https:${API_PREFIX}/legacy/${ajaxRoute}ByProp/${selectedProperty}/`);
    new kali().get(url, {
      success: (_kali, res, data) => {
        if (!data.data) {
          data.data = [];
        }

        // If mutiple_bldg is true, remove buildings where directory != 0
        if (this.buildings.length && this.state.contentData.multiple_bldg) {
          this.buildings = this.buildings.filter((building) => {
            return building.d_num === "0"
          });
        }

        this.setContentSectionData(data.data, selectedProperty, section, () => {
          // assume cb handles turning off loading
          if (typeof cb !== 'function') {
            // HACK: give table time to render
            window.setTimeout(() => {
              this.props.portal.setState({
                loading: false,
              });
            }, 250);
          }
        });

        if (typeof cb === 'function') {
          cb(data);
        }
      },

      failure: (_kali, err) => {
        console.error(err.statusText, err);
        this.props.portal.setState({
          loading: false,
        });
        toast.error(err.statusText, {
          position: toast.POSITION.TOP_RIGHT
        });
      },

      notAuthorized: (_kali, res, data) => {
        if (data.error) {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }

        this.props.app.logout();
      }
    });
  }

  disableButton(e) {
    let requiredInput = document.getElementById('field-reference_name');
    if (requiredInput) {
      let requiredInputValue = requiredInput.value;
      if (requiredInputValue.length === 0) {
        // document.getElementById('form-submit').classList.add('prevent-upload');
        // document.getElementById('save-button').classList.add('prevent-upload');
        this.setState({
          modalError: true,
          modalErrorMessage: 'Please enter a description',
          preventUpload: true
        });
      }
    }
  }

  enableButton(e) {
    // document.getElementById('form-submit').classList.remove('prevent-upload');
    // document.getElementById('save-button').classList.remove('prevent-upload');
    this.setState({
      modalError: false,
      preventUpload: false
    });
  }

  onFormSave(e) {
    let { portal } = this.props;
    let { mode, section } = portal.props;

    let id = `form-${mode}-${section}`;
    let form = document.getElementById(id);
    let formData = new FormData(form);

    let { contentData, sectionData } = this.state;
    let orgData = sectionData[this.state.editIndex];
    let sectionFields = getSectionFields(section, contentData);

    // if (this.state.modalError == false) {
    //   let modal = document.getElementById('buildingInfoModal');
    //   if (modal) {
    //     modal.classList.add('hidden');
    //     modal.classList.remove('show');
    //   }
    // }

    let { valid, fields } = getFormFieldsStatus(mode, section, sectionFields);
    if (!valid) {
      if (this.state.inline) {
        toast.error("Changes are not saved please check mandatory fields", {
          position: toast.POSITION.TOP_RIGHT
        });

        return
      }

      console.info(this.state)
      this.setState({
        modalError: true,
        modalErrorMessage: 'Please enter all mandatory fields',
      });

      return;
    }

    let pendingChanges = getFormFieldChanges(mode, section, sectionFields, orgData);
    if (Object.keys(pendingChanges).length === 0) {
      return;
    }

    this.setState({
      modalError: false,
      modalErrorMessage: '',
    });

    // If pending changes contains file_name and we're saving a slideshow, update the formData in case it was a drag and drop.
    if (pendingChanges.file_name && ~section.indexOf('slideshow')) {
      formData.set('file_name', this.state.imgUpload);
    }

    let newData = {};
    for (let d of formData.entries()) {
      newData[d[0]] = d[1];
    }

    let reloadNeeded = false;
    for (let k in newData) {
      if (typeof newData[k] === 'object') {
        reloadNeeded = true;
        break;
      }
    }

    if (newData["ID"] === "") {
      reloadNeeded = true;
    }

    let ajaxRoute = getSectionAjaxRoute(section);
    if (section === 'slideshow' || section === 'slideshow2') {
      if (reloadNeeded) {
        ajaxRoute = 'updateImages/upload/slideShow';
      }

      if (section === 'slideshow2') {
        formData.set('show__', '2');
      }
    }

    this.props.portal.setState({
      loading: true,
    });

    let { url, headers } = this.props.app.signBroncoURL(`https:${API_PREFIX}/legacy/${ajaxRoute}/`);
    console.log(url);
    new kali({
      headers,
      form: formData,
    }).post(url, {
      success: (_kali, res, data) => {
        if (reloadNeeded) {
          this.fetchDashboardContentSection(this.props.selectedProperty, section, () => {
            // HACK: make it look like it did something
            window.setTimeout(() => {
              this.props.portal.setState({
                loading: false,
              });
              this.setState({
                editIndex: -1,
                inline: false,
                modal: false,
                pendingChanges: false,
                clearForm: true,
              });
            }, 500);
          });
          return;
        }

        let { sectionData } = this.state;
        sectionData[this.state.editIndex] = newData;
        this.setState({
          sectionData,
          editIndex: -1,
          modal: false,
          inline: false,
          pendingChanges: false,
        }, (e) => {
          // HACK: make it look like it did something
          window.setTimeout(() => {
            this.props.portal.setState({
              loading: false,
            });

            if (typeof this.saveButtonCB === 'function') {
              this.saveButtonCB();
            }
          }, 500);
        });
        console.log('created/updated');
      },

      failure: (_kali, err) => {
        // HACK: make it look like it did something
        window.setTimeout(() => {
          this.props.portal.setState({
            loading: false,
          }, () => {
            toast.error(err.statusText, {
              position: toast.POSITION.TOP_RIGHT
            });
          });
        }, 500);
      },

      serverError: (_kali, res, err) => {
        // HACK: make it look like it did something
        window.setTimeout(() => {
          this.props.portal.setState({
            loading: false,
          }, () => {
            let errMessage;
            if (err && err.error) {
              errMessage = err.error;
            }

            if (err && err.data && ~err.data.Message.indexOf('Incorrect string value')) {
              errMessage = 'Invalid character(s) in text field and/or file name.';
            }

            if (errMessage) {
              toast.error(errMessage, {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          });
        }, 500);
      },

      notAuthorized: (_kali, res, data) => {
        if (data.error) {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }

        this.props.app.logout();
      }
    });
  }

  openBuildingInfo = () => {
    console.log('Show building info modal.');
    let modal = document.getElementById('buildingInfoModal');
    if (modal) {
      modal.classList.add('show');
      modal.classList.remove('hidden');
    }
  }

  closeBuildingInfo = () => {
    let pendingChanges = (document.querySelectorAll('form .pending-changes').length > 0);
    if (pendingChanges) {
      if (!window.confirm('You have pending changes, would you like to continue without saving?')) {
        return;
      }
    }

    this.setState({
      editIndex: -1,
      modal: false,
    });

    console.log('Show building info modal.');
    let modal = document.getElementById('buildingInfoModal');
    if (modal) {
      modal.classList.add('hidden');
      modal.classList.remove('show');
    }
  }

  renderBuildingInfo(mode, section) {
    if (mode !== 'content') {
      return;
    }

    if (section !== 'buildingInfo') {
      return;
    }

    return (
      <Fragment>
        <div className="editBuildingInfo">
          <i onClick={this.openBuildingInfo} class="fa fa-window-restore"> <span>Edit</span></i>
        </div>
        <div onClick={this.openBuildingInfo} className="buildingInfoForm">
          <div>
            {this.renderForm(mode, section, true)}
          </div>
        </div>
        <div id="buildingInfoModal" className="hidden">
          <div className="buildinginfoModal">
            <div className="editBuildingInfo-header">
              <h2>Edit</h2>
              <i onClick={this.closeBuildingInfo} class="fa fa-window-close"></i>
            </div>
            {this.renderForm(mode, section)}
            <div className="form-submit">
              {this.state.modalError &&
                <div className="left-float error-msg">{this.state.modalErrorMessage}</div>
              }
              <input
                className={`btn save ${((!this.state.pendingChanges) ? '' : 'pending-changes')}`}
                type="button"
                value="Submit"
                onClick={this.onFormSave.bind(this)}
              />
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  renderPDFUpload(mode, section) {
    if (mode !== 'content') {
      return;
    }

    if (section !== 'pdfUpload') {
      return;
    }

    return (
      <Fragment>
        <div className="text-label-info-left full-width-div">
          {this.renderForm(mode, section)}

          <div className="form-submit">
            <input
              className={`btn save ${((!this.state.pendingChanges) ? '' : 'pending-changes')}`}
              type="button"
              value="Submit"
              onClick={this.onFormSave.bind(this)}
            />
            <br />
            <div className="pdf_note">
              <br />
              NOTE:
              If you are uploading a new PDF, it may take a few seconds. Please do not click Submit more than once.
              <br />
              Maximum size of PDF files is 5 MB.
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  renderSlideshow(mode, section) {
    if (mode !== 'content') {
      return;
    }

    if (section !== 'slideshow' && section !== 'slideshow2') {
      return;
    }

    let pelican = false;
    if (this.props.portalSection === 'slideshow') {
      if (this.state.contentData.video_upload === '1') {
        if (this.state.contentData.multiple_bldg === '1') {
          pelican = true;
        }
      }
    }

    if (pelican) {
      const PELICAN_PREFIX = process.env.REACT_APP_PELICAN_PREFIX;

      let sessionKey = encodeURIComponent(this.props.app.session());

      return (
        <div>
          {section === 'slideshow' &&
            <p>Image resolution: {this.state.contentData.attr_max_w} x {this.state.contentData.attr_max_h} (pixels)</p>
          }
          <iframe
            style={{
              height: "75vh"
            }}
            title="pelican"
            src={`${PELICAN_PREFIX}?propNum=${this.props.selectedProperty}&session_key=${sessionKey}`}
          />
        </div>
      )
    }

    let sortedData = this.state.sectionData.filter((data, i) => {
      data.editIndex = i;

      if (section === 'slideshow') {
        if (data.show__ === '1') {
          return data;
        }
      }

      if (section === 'slideshow2') {
        if (data.show__ === '2') {
          return data;
        }
      }

      return false;
    });

    sortedData.sort((a, b) => {
      let aPosition = parseInt(a['position__']) || 0;
      let bPosition = parseInt(b['position__']) || 0;

      if (aPosition > bPosition) return 1;
      if (aPosition < bPosition) return -1;

      if (a['reference_name'].toLowerCase() > b['reference_name'].toLowerCase()) return 1;
      if (a['reference_name'].toLowerCase() < b['reference_name'].toLowerCase()) return -1;

      if (a['file_name'] > b['file_name']) return 1;
      if (a['file_name'] < b['file_name']) return -1;

      return 0;
    });

    const NO_IMG = '/assets/images/No-image.png';
    const IMG_PATH = 'https://secure.touchsource.com/Updates/PROP_NUM/Common/Program/';
    const IMG_PATH_SMALL = `${IMG_PATH}sm_`;

    return (
      <Fragment>
        {section === 'slideshow' &&
          <p className="imageResolution">Image resolution: {this.state.contentData.attr_max_w} x {this.state.contentData.attr_max_h} (pixels)</p>
        }
        {section === 'slideshow2' &&
          <p className="imageResolution">Image resolution: {this.state.contentData.ss_2_max_w} x {this.state.contentData.ss_2_max_h} (pixels)</p>
        }
        <div className="mandatory-subtitle">
          <button className="btn btn-secondary right-float grid-box-button"
            onClick={() => {
              this.setState({
                editIndex: -1,
                inline: false,
                modal: true,
                pendingChanges: true,
                clearForm: false
              });
            }}
          >Add New</button>
        </div>

        <div className="main-grid-box">
          <ul>
            {sortedData.map((data, i) => {
              let src = NO_IMG;
              if (data.file_name) {
                let propNum = sessionStorage['selected_property_number'];
                src = `${IMG_PATH_SMALL}${data.file_name}`.replace('PROP_NUM', propNum);
              }

              return (
                <li
                  key={i}
                  onClick={() => {
                    this.setState({
                      editIndex: data.editIndex,
                      modal: true,
                      pendingChanges: false,
                    });
                  }}
                >
                  <div className="thumb-img">
                    <div className="thumb-count">
                      <label>{data.position__}</label>
                    </div>

                    <a href>
                      <img
                        src={src}
                        onError={(e) => {
                          e.target.src = NO_IMG;
                        }}
                        style={{
                          border: '1px solid rgb(204, 204, 204)',
                        }} alt="empty" />
                    </a>
                  </div>

                  <div className="thumb-info"><strong>Description:</strong> {data.reference_name}</div>
                  <div className="thumb-info"><strong>Date Range:</strong> {formatDateShort(data.start_date)} - {formatDateShort(data.expire_date)}</div>
                  <div className="thumb-info"><strong>Duration:</strong> {data.duration || 7}s</div>
                </li>
              )
            })}
          </ul>
        </div>
      </Fragment>
    )
  }

  renderTable(mode, section) {
    if (mode !== 'content') {
      return;
    }

    if (section === 'buildingInfo') {
      return;
    }

    if (section === 'pdfUpload') {
      return;
    }

    if (section === 'slideshow' || section === 'slideshow2') {
      return;
    }

    return (
      <List
        app={this.props.app}
        parent={this}

        contentData={this.state.contentData}
        editIndex={this.state.editIndex}
        inline={this.state.inline}
        mode={mode}
        section={section}
        sectionData={this.state.sectionData}
        selectData={buildSelectData(this.companies, this.buildings)}
      />
    )
  }

  renderForm(mode, section, formHasNoID = false) {
    return (
      <Form
        parent={this}

        contentData={this.state.contentData}
        editIndex={this.state.editIndex}
        clearForm={this.state.clearForm || false}
        mode={mode}
        section={section}
        formHasNoID={formHasNoID}
        sectionData={this.state.sectionData}
        selectData={buildSelectData(this.companies, this.buildings)}
      />
    )
  }

  renderModalForm(mode, section) {
    if (mode !== 'content') {
      return;
    }

    if (section === 'buildingInfo') {
      return;
    }

    if (section === 'pdfUpload') {
      return;
    }

    // if (section === 'slideshow' || section === 'slideshow2') {
    //   return;
    // }

    let title = 'Add New';
    if (this.state.editIndex !== -1) {
      title = 'Edit';
    }

    let modalClassName = '';
    if (this.state.modalError) {
      modalClassName = 'error';
    }

    let formSubmitClassList = ['right-float'];
    let saveButtonClassList = ['btn', 'save'];

    if (this.state.preventUpload) {
      formSubmitClassList.push('prevent-upload');
      saveButtonClassList.push('prevent-upload');
    }

    if (this.state.pendingChanges) {
      saveButtonClassList.push('pending-changes');
    }

    return (
      <div className="modal-container" style={{
        display: (this.state.modal) ? 'flex' : 'none',
      }}>
        <div id="modal" className={`${modalClassName}`}>
          <div className="edit-form-popup">
            <div className="modalInfo">
              <h1>{title}
                <span>
                  <i className="fa fa-window-close" aria-hidden="true" onClick={() => {
                    this.setState({
                      editIndex: -1,
                      inline: false,
                      modal: false,
                      pendingChanges: false,
                      clearForm: true,
                      imgUpload: {},
                    });
                  }}></i>
                </span>
              </h1>
              <p className="mandatory">* Required Fields</p>
            </div>
            <div className="text-label-info-left popup_scroll nicescroll">
              {(section === 'slideshow' || section === 'slideshow2') &&
                <div class="mandatory-subtitle">
                  <span>File must be under 10 MB, JPG (RGB) or PNG only<br />(CMYK color and PDFs are not supported)</span>
                  <br />
                  <br />
                </div>
              }
              {this.renderForm(mode, section)}
              <div className="form-submit clear pd-5-10">
                {this.state.modalError &&
                  <div className="left-float error-msg">{this.state.modalErrorMessage}</div>
                }

                <div id="form-submit" className={formSubmitClassList.join(' ')}>
                  <input
                    id="save-button"
                    className={saveButtonClassList.join(' ')}
                    type="button"
                    value={'Save'}
                    onClick={(e) => {
                      this.onFormSave(e);
                      // this.setState({
                      // imgUpload: {},
                      // });
                    }}
                  />

                  {this.state.editIndex !== -1 &&
                    <input className="btn btn-secondary" type="button" value="Delete"
                      onClick={() => {
                        let data = this.state.sectionData[this.state.editIndex] || {};
                        deleteRecord(this.props.portal, this, section, data.ID, this);

                        this.setState({ imgUpload: {} });
                      }}
                    />
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.redirect && window.location.pathname !== this.state.redirectTo) {
      return (
        <Redirect
          push={false}
          to={this.state.redirectTo}
        />
      )
    }

    let contentData = this.state.contentData || [];
    if (Object.keys(contentData).length === 0) {
      return '';
    }

    let { portal } = this.props;
    let { mode, section } = portal.props;
    let enabledNavData = getNavData(contentData);

    let d = getSectionNavData(section);
    let title = this.state.contentData[d.labelKey] || d.defaultLabel;

    let pelicanStatus = false;
    if (mode === 'content') {
      if (this.state.contentData.video_upload === '1') {
        if (this.state.contentData.multiple_bldg === '1') {
          pelicanStatus = true;
        }
      }
    }

    const viewProgramButton = () => {
      if (pelicanStatus === true) {
        return <li>
          <a href
            id="viewProgramClick"
            onClick={(e) => {
              let programURL = `https://mesh01.touchsource.com/`;
              window.open(programURL, '_blank');
            }}
          >Live View Login</a>
        </li>
      }
      else {
        return <li>
          <div className="tooltip-container">
            <div className="tooltip tooltip-right tooltip-wide tooltip-dark">
              <i className="fa fa-question-circle"
                onMouseOver={() => {
                  this.setState({
                    viewProgramTooltipActive: true
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    viewProgramTooltipActive: false
                  });
                }}
              ></i>
              {this.state.viewProgramTooltipActive &&
                <div className="tooltip-message">
                  Changes can take up to 60 seconds to appear.
                <div className="tooltip-triangle"></div></div>
              }
            </div>
          </div>
          <a href
            id="viewProgramClick"
            onClick={(e) => {
              let programURL = `http://pinto.touchsource.com/TS41/Startup.asp?PropNum=${this.props.selectedProperty}`;
              window.open(programURL, '_blank');
            }}
          >View Program</a>
        </li>
      }
    }

    const submitChangesButton = () => {
      if (this.state.usersData.update_type == "W") {
        return <div className="publish-button">
          <div className="tooltip tooltip-left tooltip-wide tooltip-dark">
            <i className="fa fa-question-circle"
              onMouseOver={() => {
                this.setState({
                  publishTooltipActive: true
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  publishTooltipActive: false
                });
              }}
            ></i>
            {this.state.publishTooltipActive &&
              <div className="tooltip-message">
                Content will be updated and downloaded.
                <div className="tooltip-triangle"></div></div>
            }
          </div>
          <button
            type="button"
            className="submitChangesButton btn right-float"
            onClick={() => {
              this.props.app.fetchBarbarian(this.state.usersData.update_type);
            }}
          ><i class="fa fa-sign-out"></i> Publish Changes and Download</button>
        </div>
      }
      if (this.props.portalSection === 'slideshow') {
        if (this.state.contentData.video_upload === '1' && (this.state.contentData.multiple_bldg === '1')) {
          return <div className="publish-button">
            <div className="tooltip tooltip-left tooltip-wide tooltip-dark">
              <i className="fa fa-question-circle"
                onMouseOver={() => {
                  this.setState({
                    publishTooltipActive: true
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    publishTooltipActive: false
                  });
                }}
              ></i>
              {this.state.publishTooltipActive &&
                <div className="tooltip-message">
                  Systems will update slideshow content immediately.
                <div className="tooltip-triangle"></div></div>
              }
            </div>
            <button
              type="button"
              className="submitChangesButton btn right-float disabled"
            ><i class="fa fa-image"></i> Changes Made Live</button>
          </div>
        }
      }
      if (this.props.portalSection === 'slideshow') {
        if (this.state.contentData.video_upload !== '1' || (this.state.contentData.multiple_bldg !== '1')) {
          return <div className="publish-button">
            <div className="tooltip tooltip-left tooltip-wide tooltip-dark">
              <i className="fa fa-question-circle"
                onMouseOver={() => {
                  this.setState({
                    publishTooltipActive: true
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    publishTooltipActive: false
                  });
                }}
              ></i>
              {this.state.publishTooltipActive &&
                <div className="tooltip-message">
                  Systems will update their content overnight.
                <div className="tooltip-triangle"></div></div>
              }
            </div>
            <button
              type="button"
              className="submitChangesButton btn right-float"
              onClick={() => {
                this.props.app.fetchBarbarian(this.state.usersData.update_type);
              }}
            ><i class="fa fa-sign-out"></i> Publish Changes</button>
          </div>
        }
      }
      else {
        return <div className="publish-button">
          <div className="tooltip tooltip-left tooltip-wide tooltip-dark">
            <i className="fa fa-question-circle"
              onMouseOver={() => {
                this.setState({
                  publishTooltipActive: true
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  publishTooltipActive: false
                });
              }}
            ></i>
            {this.state.publishTooltipActive &&
              <div className="tooltip-message">
                Systems will update their content overnight.
                <div className="tooltip-triangle"></div></div>
            }
          </div>
          <button
            type="button"
            className="submitChangesButton btn right-float"
            onClick={() => {
              this.props.app.fetchBarbarian(this.state.usersData.update_type);
            }}
          ><i class="fa fa-sign-out"></i> Publish Changes</button>
        </div>
      }
    }

    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    let expireDate = 0;
    if (this.state.usersData.online_expires) {
      expireDate = new Date(Date.parse(this.state.usersData.online_expires));
      expireDate.setHours(0);
      expireDate.setMinutes(0);
      expireDate.setSeconds(0);
    }

    let diff = Math.round((expireDate - now) / 1000 / 60 / 60 / 24);
    if (diff < 1 || diff > 90) {
      diff = false;
    }

    let expireDateStr = '';
    if (diff) {
      let year = expireDate.getFullYear();
      let month = (1 + expireDate.getMonth()).toString().padStart(2, '0');
      let day = expireDate.getDate().toString().padStart(2, '0');
      expireDateStr = month + '/' + day + '/' + year;
    }

    console.log('Here it is: ' + this.state.usersData.updating_email);

    return (
      <Fragment>
        <div className="header-bottom">

          {internetExplorer &&
            <div className="ie-message">
              <span>We noticed you are using Internet Explorer.</span>
              To have the best portal experience, please switch to the <a target="_blank" href="https://www.google.com/chrome/">Chrome web browser</a>.
            </div>
          }

          {this.state.usersData.online_expires && this.state.usersData.reseller === '' &&
            <div className="renew-message">
              {diff &&
                <Fragment>
                  <span className="renew-icon message-icon"></span>
                  Subscription expires {expireDateStr} – invoice will be provided 60 days prior to due date. Please click 'Account' above to ensure that billing information is correct. Please email renewals@touchsource.com with additional questions.
                </Fragment>
              }
            </div>
          }

          {this.state.usersData.spec_instructions &&
            <div className="special_inst_div">
              <a href type="button" className="btn"
                onClick={() => {
                  this.setState({
                    specialInstructionsHidden: !this.state.specialInstructionsHidden,
                  });
                }}
              >Special Instructions</a>


              {!this.state.specialInstructionsHidden &&
                <div className="special_inst_pop" >
                  <h4>Special Instructions</h4>
                  <span className="cus-close-btn close-btn-position">
                    <i className="fa fa-window-close" onClick={() => {
                      this.setState({
                        specialInstructionsHidden: !this.state.specialInstructionsHidden,
                      });
                    }} aria-hidden="true"></i></span>
                  <div className="clear"></div>
                  <p >
                    <div dangerouslySetInnerHTML={{ __html: this.state.usersData.spec_instructions }} />
                  </p>
                </div>
              }
            </div>
          }

          {submitChangesButton()}

        </div>

        <div className={`content-body ${mode} ${section}`}>
          <div className="left-navigation">
            <select
              value={section}
              onChange={(e) => {
                let pendingChanges = (document.querySelectorAll('form .pending-changes').length > 0);
                if (pendingChanges) {
                  if (!window.confirm('You have pending changes, would you like to continue without saving?')) {
                    return;
                  }
                }

                this.setState({
                  redirect: true,
                  redirectTo: `/dashboard/content/${e.target.value}`,
                });
              }}
            >
              {enabledNavData.map((data) => {
                if (!data) {
                  return false;
                }

                return (
                  <option
                    key={data.route}
                    value={data.route}
                  >
                    {this.state.contentData[data.labelKey] || data.defaultLabel}
                  </option>
                )
              })}
            </select>

            <ul>
              {enabledNavData.map((data) => {
                if (!data) {
                  return false;
                }

                let activeClassName = 'active';
                if (section !== data.route) {
                  activeClassName = '';
                }

                if (internetExplorer === true) {
                  return (
                    <li
                      key={data.route}
                      className={activeClassName}
                      id={data.id}
                    >
                      <NavLink
                        to={`/dashboard/content/${data.route}`}
                        activeClassName='active'
                        id={data.id}
                      >{this.state.contentData[data.labelKey] || data.defaultLabel}</NavLink>
                    </li>
                  )
                }

                return (
                  <li
                    key={data.route}
                    className={activeClassName}
                  >
                    <NavLink
                      to={`/dashboard/content/${data.route}`}
                      activeClassName='active'
                      id={data.id}
                      onClick={(e) => {
                        if (!window.ignorePendingChanges) {
                          e.preventDefault();

                          let pendingChanges = (document.querySelectorAll('form .pending-changes').length > 0);
                          if (pendingChanges) {
                            if (!window.confirm('You have pending changes, would you like to continue without saving?')) {
                              e.preventDefault();
                              return;
                            }
                          }
                        }

                        window.ignorePendingChanges = true;
                        e.target.click();
                      }}
                    >{this.state.contentData[data.labelKey] || data.defaultLabel}</NavLink>
                  </li>
                )
              })}
              {viewProgramButton()}
            </ul>
          </div>

          <div className={`content-section ${mode} ${section}`}>
            <h1>{title}</h1>
            {this.renderBuildingInfo(mode, section)}
            {this.renderPDFUpload(mode, section)}
            {this.renderSlideshow(mode, section)}

            {this.renderTable(mode, section)}
            {this.renderModalForm(mode, section)}

            {this.props.DEBUG &&
              <div
                className="debug-raw-data-box"
              >
                <pre>
                  <code>
                    COUNT: {this.state.sectionData.length + '\n'}
                    {JSON.stringify(this.state.sectionData, null, 2)}
                  </code>
                </pre>
              </div>
            }
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Content;

window.addEventListener('load', () => {
  let keys = [];
  for (let i = 0, l = sessionStorage.length; i < l; i++) {
    let key = sessionStorage.key(i);
    if (~key.indexOf('dashboard_content_')) {
      keys.push(key);
    }
  }

  for (let key of keys) {
    sessionStorage.removeItem(key);
  }
});