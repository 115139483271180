import React, { Component, Fragment } from 'react';
import {
  Link,
} from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propertySwitcherActive: false,
      userMenuActive: false,
      hamburger: false,
      usersData: this.props.usersData || {}
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.propertySwitcherActive !== this.props.propertySwitcherActive) {
      this.setState({
        propertySwitcherActive: this.props.propertySwitcherActive,
        userMenuActive: false,
      });
    }

    if (prevProps.userMenuActive !== this.props.userMenuActive) {
      this.setState({
        propertySwitcherActive: false,
        userMenuActive: this.props.userMenuActive,
      });
    }

    if (prevProps.usersData !== this.props.usersData) {
      this.setState({
        usersData: this.props.usersData,
      });

      return;
    }
  }

  renderLogo() {
    return (
      <div className="logo">
        <Link
          to="/dashboard/content/companies"
        >
          <img src="/assets/images/touchsource-logo.svg" alt="logo" />
        </Link>
      </div>
    )
  }

  renderMenu() {
    return (
      <div className="main-menu"
        onClick={() => {
          this.setState({
            propertySwitcherActive: false,
            userMenuActive: false,
          })
        }}
      >
        <ul>
          {this.props.portal.navLinks.map((portalMode) => {
            let active = 'active';
            if (!(this.props.portalMode === portalMode)) {
              active = '';
            }

            let icon = '';
            if (this.state.usersData.archive === '1' && portalMode === 'ACCOUNT') {
              icon = (
                <span class="renew-icon"></span>
              )
            }

            if (this.state.usersData.archive === '1' && portalMode === 'CONTENT') {
              active = 'disabled';
            }

            let gaId = '';
            let helpLink = '';
            let helpIcon = '';
            if (portalMode === 'CONTENT') {
              gaId = 'contentClick';
            }
            if (portalMode === 'ACCOUNT') {
              gaId = 'accountClick';
            }
            if (portalMode === 'SUPPORT') {
              gaId = 'supportClick';
            }
            if (portalMode === 'HELP') {
              gaId = 'helpClick';
              helpLink = 'helpLink';
              helpIcon = <i class="fa fa-life-ring"></i>
            }

            return (
              <li
                key={portalMode}
                className={active}
                id={helpLink}
              >
                {icon}
                <Link
                  replace
                  id={gaId}
                  to={{
                    pathname: `/dashboard/${portalMode.toLowerCase()}`,
                    state: {
                      usersData: this.props.portal.props.usersData,
                    },
                  }}
                >{helpIcon}<span>{portalMode}</span></Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  renderPropertySwitcher() {
    if (!this.props.selectedProperty) {
      return;
    }

    let propnums = this.props.portal.state.propnums;
    let properties = this.props.portal.state.properties;
    let selectedProperty = this.props.portal.state.selectedProperty;
    return (
      <div>
        <a className="" title={properties[selectedProperty]} href
          onClick={() => {
            this.setState({
              propertySwitcherActive: !this.state.propertySwitcherActive,
              userMenuActive: false,
            })
          }}
        >
          {properties[selectedProperty]}
          {this.state.propertySwitcherActive &&
            <div className="dropdown-menu">
              <ul className="nicescroll">
                {propnums.map((n) => {
                  let name = properties[n];

                  let selected = 'selected';
                  if (!(selectedProperty === n)) {
                    selected = '';
                  }
                  return (
                    <li
                      key={n}
                      className={selected}
                      datakey={n}
                      onClick={() => {
                        this.props.portal.setSelectedPropertyNumber(n)
                      }}
                    >
                      {name}
                    </li>
                  )
                })}
              </ul>
            </div>
          }
        </a>
      </div>
    )
  }

  renderUserName() {
    let email = sessionStorage['email'];
    return (
      <div
        onClick={() => {
          this.setState({
            propertySwitcherActive: false,
            userMenuActive: !this.state.userMenuActive,
          })
        }}
      >
        <a onClick={() => {
            this.setState({
              userMenuActive: !this.state.userMenuActive,
              propertySwitcherActive: false,
            })
          }}>
          <i className="fa fa-user-o" aria-hidden="true"></i>{email}
        </a>

        <a href>
          {this.state.userMenuActive &&
            <div className="dropdown-menu">
              <ul className="nicescroll">
                <li onClick={() => { this.props.app.logout(); }}>
                  Logout <i className="right-float fa fa-sign-out"></i>
                </li>
              </ul>
            </div>
          }
        </a>
      </div>
    )
  }

  render() {
    if (!this.props.app.loggedIn()) {
      return (
        <div className="header">
          {this.renderLogo()}
        </div>
      )
    }

    return (
      <div className="header">

        {this.renderLogo()}

        <div className={`hamburger-menu ${this.state.hamburger && 'hamburger-close'}`}
          onClick={() => {
            this.setState({
              propertySwitcherActive: !this.state.hamburger,
              userMenuActive: !this.state.hamburger,
              hamburger: !this.state.hamburger,
            });
          }}
        >
          <span></span>
        </div>

        <div className={`header-right ${this.state.hamburger && 'header-right-show'}`}>

          <div className="user-box">
            <div className="property-switcher"
              onMouseLeave={() => {
                this.setState({
                  propertySwitcherActive: false,
                });
              }}>
              <div className="property-switcher-container">
                {this.renderPropertySwitcher()}
              </div>
              <i onClick={() => {
                this.setState({
                  propertySwitcherActive: !this.state.propertySwitcherActive,
                });
              }} class="fas fa-chevron-down"></i>
            </div>
            <span>|</span>
            <div className="user-name"
              onMouseLeave={() => {
                this.setState({
                  userMenuActive: false,
                });
              }}>
              <div className="user-name-container">
                {this.renderUserName()}
              </div>
            </div>
          </div>

          {this.props.selectedProperty &&
            this.renderMenu()
          }

        </div>
      </div>
    )
  }
}

export default Header;