import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { toast } from 'react-toastify';
import Cookie from 'js-cookie';
import md5 from 'md5';
import kali from 'kali';

import Login from './component/Login';
import Portal from './component/Portal';

import ThankYou from './component/dashboard/thankYou';
import ErrorNotFound from './component/errorNotFound';

const ATLAS_SESSION_KEY = 'AE39o8OtX83SzJf_EsK3zzqRMofaPCFZ10KrlZcT3UmrwtqyDt4d48ZBBH2w=';

const BRONCO_PREFIX = process.env.REACT_APP_BRONCO_PREFIX;

let doc = document.documentElement;
doc.setAttribute('data-useragent', navigator.userAgent);

class App extends Component {
  constructor(props) {
    super(props);

    // from-atlas support
    let l = window.location;
    if (~l.hash.indexOf('from-atlas')) {
      let propNum = l.hash.replace('#/from-atlas/', '');
      propNum = parseInt(propNum);

      let propNums = [];
      propNums.push(propNum);

      let properties = {
        [propNum.toString()]: propNum,
      };

      window.sessionStorage['email'] = 'Atlas';
      window.sessionStorage['selected_property_number'] = propNum;
      window.sessionStorage['propnums'] = JSON.stringify(propNums);
      window.sessionStorage['properties'] = JSON.stringify(properties);

      Cookie.set('session', ATLAS_SESSION_KEY);
      return window.location.href = `${l.protocol}//${l.host}/dashboard/content/companies`;
    }

    this.state = {
      auth: this.loggedIn(),
      auth_2fa: false,
      auth_has_2fa: false,
    };
  }

  // componentDidMount() {
  //   window.onbeforeunload = (e) => {
  //     this.logout(true);

  //     // the absence of a returnValue property on the event will guarantee the browser unload happens
  //     delete e['returnValue'];
  //   }
  // }

  session() {
    return Cookie.get('session');
  }

  loggedIn() {
    let auth = Cookie.get('session');
    return Boolean(auth);
  }

  login(sessionKey, auth2FA = false) {
    let auth = Cookie.get('session');
    if (!auth) {
      Cookie.set('session', sessionKey);
      this.setState({
        auth: true,
        auth_2fa: auth2FA,
      });
    }
  }

  logout(ajax = true) {
    let auth = Cookie.get('session');
    if (auth) {
      Cookie.remove('session');
      sessionStorage.clear();
      localStorage.clear();

      this.setState({
        auth: false,
      }, () => {
        if (ajax) {
          this.broncoLogout(auth);
        }
      });
    }
  }

  broncoLogin(email, pass, auth2FACode = '', component) {
    if (typeof component.setState === 'undefined') {
      console.error('must be called from other react component')
      return;
    }

    let uri = 'https:' + BRONCO_PREFIX + '/login';
    new kali({
      body: {
        "email": email,
        "pass": pass,
        "auth_2fa_code": auth2FACode,
      }
    }).post(uri, {
      success: (_kali, res, data) => {
        if (data.user['2fa']) {
          window.sessionStorage['2fa_img'] = data.user['2fa_img'];

          component.setState({
            mode: '2FA',
            email,
            password: pass,
            auth2FACode: '',
          });

          return;
        }

        component.setState({
          error: false,
          errorMessage: '',
        }, () => {
          delete sessionStorage['2fa_img'];

          let user = data.user;
          if (!user) {
            // TODO: error handling?
          }

          let metadata = user.metadata;
          if (!metadata) {
            // TODO: error handling?
          }

          let properties = JSON.stringify(metadata.properties);
          sessionStorage['propnums'] = properties;
          sessionStorage['email'] = user.email;

          let session = data.session;
          if (!session) {
            // TODO: error handling?
          }

          let sessionKey = session.session_key;
          this.login(sessionKey, true);
        });
      },

      failure: (_kali, err) => {
        component.setState({
          error: true,
          errorMessage: err.message,
        });
      },

      notAuthorized: (_kali, res, data) => {
        if (data.message === 'Invalid 2FA Code') {
          component.setState({
            mode: '2FA',
            email, // : document.getElementById('email').value,
            password: pass, // : document.getElementById('password').value,
            auth2FACode: '',

            error: Boolean(auth2FACode !== ''),
            errorMessage: (auth2FACode !== '') ? data.message : '',
          });
        }
      },

      serverError: (_kali, res, data) => {
        if (data.error) {
          if (data.message === 'legacy login required') {
            window.location.href = 'http://updates.touchsource.com/TS30UI/login_41.cfm';
            return;
          }

          component.setState({
            error: true,
            errorMessage: data.message,
          });
        }
      }
    }, true);
  }

  broncoLogout(sessionKey) {
    let uri = 'https:' + BRONCO_PREFIX + '/logout';
    new kali({
      body: {
        "session_key": sessionKey,
      }
    }).post(uri, {
      success: (_kali, res, data) => {
        console.log(data);
      },

      failure: (_kali, err) => {
        console.error(err);
      },
    }, this);
  }

  signBroncoURL(url) {
    let authID = Cookie.get('session');
    let authTS = parseInt(((new Date()).getTime() / 1000), 10);
    let authKey = md5(`${authID}_${authTS}`);
    let params = `auth_id=${authID}&auth_key=${authKey}&auth_ts=${authTS}`;

    let signedURL = url + '?' + params;;
    if (~url.indexOf('?')) {
      signedURL = url + '&' + params;
    }

    return {
      url: signedURL,
      headers: {
        'X-Auth-ID': authID,
        'X-Auth-Key': authKey,
        'X-Auth-TS': authTS,
      },
    };
  }

  fetchBarbarian(updateType = '') {
    if (window.fetchingBarbarian) {
      console.log('Already fetching barbarian...');

      return;
    }
    window.fetchingBarbarian = true;
    this.setState({
      publishingChanges: true,
    });

    let propNum = sessionStorage['selected_property_number'];

    let BARBARIAN_PREFIX = 'barbarian.touchsource.com';
    let uri = 'https://' + BARBARIAN_PREFIX + `/?propnum=${propNum}&updatetype=${updateType}`;

    updateType = updateType.toUpperCase();
    if (updateType !== 'W') {
      new kali().get(uri, {
        success: (_kali, res, data) => {
          console.log(data);

          window.fetchingBarbarian = false;

          window.setTimeout(() => {
            this.setState({
              publishingChanges: false,
            });
          }, 5000);
          
          toast.success("Success", {
            position: toast.POSITION.TOP_RIGHT
          });
        },

        failure: (_kali, err) => {
          console.error(err);

          window.fetchingBarbarian = false;
          this.setState({
            publishingChanges: false,
          });
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT
          })
        },
      }, this);
    }

    if (updateType === 'W') {
      fetch(uri)
        .then((res) => {
          if (res.ok) {
            return res.blob()
          }

          return false
        })
        .then(blob => {
          if (blob) {
            // Download mdb
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${propNum}.mdb`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();  // afterwards we remove the element again

            this.setState({
              publishingChanges: false,
            });
            toast.success("Success", {
              position: toast.POSITION.TOP_RIGHT
            });

            this.logout();
          } else {
            this.setState({
              publishingChanges: false,
            });
            toast.error("Error", {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        })
        .catch(error => {
          this.setState({
            publishingChanges: false,
          });
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    }

  }

  render() {
    return (
      <Fragment>
        {this.props.DEBUG &&
          <p
            style={{
              position: 'fixed',
              right: 0,
              bottom: 0,
              margin: 0,
              padding: '5px',
              cursor: 'pointer',
              color: '#efefef',
              background: 'red',
              fontSize: '18px',
              fontWeight: 'bold',
              textAlign: 'right',
              zIndex: 99999,
            }}
            onClick={() => {
              if (this.loggedIn()) {
                this.logout();
                return;
              }

              try {
                document.getElementById('email').value = 'brianh@touchsource.com';
                document.getElementById('password').value = btoa('!y§v');
                document.getElementById('login-button').click();
              } catch (err) {
                debugger;
              }

              // return this.broncoLogin('brianh@touchsource.com', 'Doghead2', '', this);
            }}
          >{this.loggedIn() ? 'LOGOUT' : 'LOGIN'}</p>
        }

        <Router>
          <Switch>
            <Route
              exact
              path='/'
              render={() => {
                if (!this.loggedIn()) {
                  return (
                    <Redirect
                      push={false}
                      to='/login'
                    />
                  )
                }

                return (
                  <Redirect
                    push={false}
                    to='/dashboard/properties'
                  />
                )
              }}
            />

            <Route
              exact
              path='/login'
              render={() => {
                if (this.loggedIn()) {
                  return (
                    <Redirect
                      push={false}
                      to='/'
                    />
                  )
                }

                return (
                  <Login
                    app={this}
                  />
                )
              }}
            />

            <Route
              exact
              path='/password/reset/:authCode'
              render={({ match }) => {
                let { authCode } = match.params;
                if (!authCode) {
                  return (
                    <Redirect
                      push={false}
                      to='/'
                    />
                  )
                }

                return (
                  <Login
                    app={this}
                    mode={'RESET_PASSWORD'}
                    authCode={authCode}
                  />
                )
              }}
            />

            <Route
              exact
              path='/dashboard/:mode/:section'
              render={({ location, match }) => {
                if (!this.loggedIn()) {
                  return (
                    <Redirect
                      push={false}
                      to='/login'
                    />
                  )
                }
                // console.log(match.params);

                if (!location.state) {
                  location.state = {};
                }
                console.log('STATE:', location.state, location.state.usersData);
                return (
                  <Portal
                    app={this}
                    loading={this.state.loading || false}
                    publishingChanges={this.state.publishingChanges || false}
                    mode={match.params.mode}
                    section={match.params.section}
                    usersData={location.state.usersData}
                  />
                )
              }}
            />

            <Route
              exact
              path='/dashboard/:mode'
              render={({ location, match }) => {
                if (!this.loggedIn()) {
                  return (
                    <Redirect
                      push={false}
                      to='/login'
                    />
                  )
                }

                if (match.params.mode === 'content') {
                  return (
                    <Redirect
                      push={false}
                      to={{
                        pathname: '/dashboard/content/companies',
                        state: location.state || {},
                      }}
                    />
                  )
                }

                if (match.params.mode === 'account') {
                  return (
                    <Redirect
                      push={false}
                      to={{
                        pathname: '/dashboard/account/managementContactInfo',
                        state: location.state || {},
                      }}
                    />
                  )
                }

                if (match.params.mode === 'support') {
                  return (
                    <Redirect
                      push={false}
                      to={{
                        pathname: '/dashboard/support/serviceRequest',
                        state: location.state || {},
                      }}
                    />
                  )
                }

                if (match.params.mode === 'thankyou') {
                  return (
                    <Route path="*" component={ThankYou} />
                  )
                }

                return (
                  <Portal
                    app={this}
                    loading={this.state.loading || false}
                    publishingChanges={this.state.publishingChanges || false}
                    mode={match.params.mode}
                  />
                )
              }}
            />

            <Route
              exact
              path='/dashboard'
              render={({ match }) => {
                return (
                  <Redirect
                    push={false}
                    to='/dashboard/properties'
                  />
                )
              }}
            />

            {/* 404 Error */}
            <Route path="*" component={ErrorNotFound} />

          </Switch>
        </Router>
      </Fragment>
    )
  }
}

export default App;
