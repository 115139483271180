import React, { Component } from 'react';

export default class ThankYou extends Component {

    render() {
        return (
            <div id="thankyou">
                <h1><span>Thank You</span><br/>Your request has been submitted</h1>
                <p>
                        If we need more information someone from our staff will be in touch with you shortly.
                </p>
                <a className="btn" href="/dashboard/content/companies">Go Back to Home</a>
            </div>
        );
    }
}