import React, { Component, Fragment } from 'react';

import {
  getSectionFields,
  getSectionFieldsByFormName,
} from '../data/fieldData.js';

import DateField from './field/Date.js';
import ImgUpload from './field/ImgUpload.js';
import PDFUpload from './field/PDFUpload.js';
import Radio from './field/Radio.js';
import Select from './field/Select.js';
import Text from './field/Text.js';
import NumberField from './field/Number.js';
import TextArea from './field/TextArea.js';
import Time from './field/Time.js';

export const states = [
  {
    key: "Alabama",
    val: "AL",
  },
  {
    key: "Alaska",
    val: "AK",
  },
  {
    key: "Arizona",
    val: "AZ",
  }, {
    key: "Arkansas",
    val: "AR",
  },
  {
    key: "California",
    val: "CA",
  },
  {
    key: "Colorado",
    val: "CO",
  },
  {
    key: "Connecticut",
    val: "CT",
  },
  {
    key: "Delaware",
    val: "DE",
  }, 
  {
    key: "District of Columbia",
    val: "DC",
  },
  {
    key: "Florida",
    val: "FL",
  },
  {
    key: "Georgia",
    val: "GA",
  },
  {
    key: "Guam",
    val: "GU",
  },
  {
    key: "Hawaii",
    val: "HI",
  }, {
    key: "Idaho",
    val: "ID",
  },
  {
    key: "Illinois",
    val: "IL",
  },
  {
    key: "Indiana",
    val: "IN",
  },
  {
    key: "Iowa",
    val: "IA",
  }, {
    key: "Kansas",
    val: "KS",
  },
  {
    key: "Kentucky",
    val: "KY",
  },
  {
    key: "Louisiana",
    val: "LA",
  },
  {
    key: "Maine",
    val: "ME",
  },
  {
    key: "Marshall Islands",
    val: "MH",
  },
  {
    key: "Maryland",
    val: "MD",
  },
  {
    key: "Massachusetts",
    val: "MA",
  },
  {
    key: "Michigan",
    val: "MI",
  }, {
    key: "Minnesota",
    val: "MN",
  },
  {
    key: "Mississippi",
    val: "MS",
  },
  {
    key: "Missouri",
    val: "MO",
  },
  {
    key: "Montana",
    val: "MT",
  },
  {
    key: "Nebraska",
    val: "NE",
  },
  {
    key: "Nevada",
    val: "NV",
  },
  {
    key: "New Hampshire",
    val: "NH",
  },
  {
    key: "New Jersey",
    val: "NJ",
  },
  {
    key: "New Mexico",
    val: "NM",
  },
  {
    key: "New York",
    val: "NY",
  },
  {
    key: "North Carolina",
    val: "NC",
  },
  {
    key: "North Dakota",
    val: "ND",
  },
  {
    key: "Ohio",
    val: "OH",
  },
  {
    key: "Oklahoma",
    val: "OK",
  },
  {
    key: "Oregon",
    val: "OR",
  },
  {
    key: "Pennsylvania",
    val: "PA",
  },
  {
    key: "Puerto Rico",
    val: "PR",
  },
  {
    key: "Rhode Island",
    val: "RI",
  },
  {
    key: "South Carolina",
    val: "SC",
  },
  {
    key: "South Dakota",
    val: "SD",
  },
  {
    key: "Tennessee",
    val: "TN",
  },
  {
    key: "Texas",
    val: "TX",
  },
  {
    key: "Utah",
    val: "UT",
  },
  {
    key: "Vermont",
    val: "VT",
  },
  {
    key: "Virgin Islands",
    val: "VI",
  },
  {
    key: "Virginia",
    val: "VA",
  },
  {
    key: "Washington",
    val: "WA",
  },
  {
    key: "West Virginia",
    val: "WV",
  },
  {
    key: "Wisconsin",
    val: "WI",
  },
  {
    key: "Wyoming",
    val: "WY",
  },
  {
    key: "Alberta",
    val: "AB",
  },
  {
    key: "British Columbia",
    val: "BC",
  },
  {
    key: "Manitoba",
    val: "MB",
  }, {
    key: "New Brunswick",
    val: "NB",
  },
  {
    key: "Newfoundland",
    val: "NF",
  },
  {
    key: "Northwest Territory",
    val: "NT",
  },
  {
    key: "Nova Scotia",
    val: "NS",
  },
  {
    key: "Nunavut",
    val: "NU",
  },
  {
    key: "Ontario",
    val: "ON",
  },
  {
    key: "Prince Edwards Is.",
    val: "PE",
  },
  {
    key: "Quebec",
    val: "QC",
  },
  {
    key: "Saskatchewan",
    val: "SK",
  },
  {
    key: "Yukon Territory",
    val: "YK",
  },
];

export const buildSelectData = (_companies = [], _buildings = []) => {
  let companies = [];
  try {
    companies = _companies.map((data) => {
      return {
        id: data.company_id,
        key: data.company_name,
        val: data.company_name,
      };
    });

    // TODO: sort
  } catch (err) {
    // console.error(err);
  }

  let buildings = [];
  try {
    buildings = _buildings.map((data) => {
      return {
        key: data.bldg_name,
        val: data.bldg_name,
      };
    });

    // TODO: sort
  } catch (err) {
    // console.error(err);
  }

  return {
    companies,
    buildings,
    states,
  };
};

export const getFormFieldsStatus = (mode, section, sectionFields, formName = false) => {
  let id = `form-${mode}-${section}`;
  if (formName) {
    id = `form-${formName}`;
  }
  let form = document.getElementById(id);
  if (!form) {
    return {};
  }

  let formData = new FormData(form);

  let valid = true;
  let status = {};
  for (let d of formData.entries()) {
    let key = d[0];
    let val = d[1];

    let field;
    for (let sectionField of sectionFields) {
      if (sectionField.fileKey === key) {
        field = sectionField;
        break;
      }

      if (sectionField.key === key) {
        field = sectionField;
        break;
      }
    }

    // should not happen!
    if (typeof field === 'undefined') {
      debugger;
    }

    if (field.required && (!val || val === '')) {
      status[key] = false;
      valid = false;
    }
  }

  return {
    valid,
    fields: status,
  };
}

export const getFormFieldChanges = (mode, section, sectionFields, orgData = {}, formName = false) => {
  let id = `form-${mode}-${section}`;
  if (formName) {
    id = `form-${formName}`;
  }
  let form = document.getElementById(id);
  if (!form) {
    return {};
  }

  let formData = new FormData(form);

  let changes = {};
  for (let d of formData.entries()) {
    let key = d[0];
    let newVal = d[1];
    let oldVal = orgData[key] || '';

    let field;
    for (let sectionField of sectionFields) {
      if (sectionField.fileKey === key) {
        field = sectionField;
        break;
      }

      if (sectionField.key === key) {
        field = sectionField;
        break;
      }
    }

    if (field.ignore) {
      continue;
    }

    if (field.type === 'hidden') {
      continue;
    }

    // ignore time in date comparison
    if (field.type === 'date') {
      let nvDate = new Date(newVal);
      newVal = `${nvDate.getMonth()}::${nvDate.getDate()}::${nvDate.getFullYear()}`;

      let ovDate = new Date(oldVal);
      oldVal = `${ovDate.getMonth()}::${ovDate.getDate()}::${ovDate.getFullYear()}`;
    }

    if (newVal !== oldVal) {
      changes[key] = {
        old: oldVal,
        new: newVal,
      };
    }
  }

  return changes || {};
}

class Form extends Component {
  state = {
    editIndex: this.props.editIndex,
    changes: {},
    clearForm: false,
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.editIndex !== this.props.editIndex) {
      this.setState({
        editIndex: this.props.editIndex,
        changes: {},
      });
    }

    if (prevProps.clearForm !== this.props.clearForm) {
      this.setState({
        clearForm: this.props.clearForm,
      });
    }

    if (prevProps.section !== this.props.section) {
      return this.forceUpdate();
    }

    if (prevProps.modalError !== this.props.modalError) {
      debugger;
    }
  }

  onChange() {
    if (this.state.editIndex === -1) {
      return;
    }

    let { mode, section, contentData, sectionData } = this.props;
    let orgData = sectionData[this.state.editIndex];

    let sectionFields;
    if (this.props.formName) {
      sectionFields = getSectionFieldsByFormName(this.props.formName, section, contentData);
    } else {
      sectionFields = getSectionFields(section, contentData);
    }

    let changes = getFormFieldChanges(mode, section, sectionFields, orgData, this.props.formName);
    this.props.parent.setState({
      pendingChanges: (Object.keys(changes).length !== 0) ? true : false,
    });
    this.setState({
      changes,
    });
    // console.log('CHANGES:');
    // console.log(JSON.stringify(changes, null, 2));

    let { valid } = getFormFieldsStatus(mode, section, sectionFields, this.props.formName);
    if (valid) {
      this.props.parent.setState({
        modalError: false,
      });
    }
  }

  render() {
    let { mode, section, contentData, sectionData, formName } = this.props;
    let id = `form-${mode}-${section}`;
    if (formName) {
      id = `form-${formName}`;
    }
    if (this.props.formHasNoID) {
      id = '';
    }

    if (section === 'slideshow2') {
      section = 'slideshow';
    }

    let sectionFields;
    if (this.props.formName) {
      sectionFields = getSectionFieldsByFormName(this.props.formName, section, contentData);
    } else {
      sectionFields = getSectionFields(section, contentData);
    }

    return (
      <form id={id}>
        {sectionFields.map((field) => {
          if (field.ignore) {
            return '';
          }

          let val = field.value;
          try {
            let editData = sectionData[this.state.editIndex];
            val = editData[field.key];
          } catch (err) {
            val = field.default || '';
            // console.error(err);
          }
          // console.log(field.key, val);

          if (this.state.editIndex === -1) {
            val = field.default || '';
            if (field.key === 'prop_num') {
              let selectedProperty = sessionStorage['selected_property_number'];
              val = selectedProperty;
            }
          }

          let label = contentData[field.labelKey] || field.defaultLabel || field.key;
          let labelStr = label;
          if (field.required) {
            label = (
              <Fragment>
                <span>* </span>{label}
              </Fragment>
            );
          }

          if (field.type === 'hidden' && this.props.DEBUG) {
            return (
              <div className="edit-form"
                key={field.key}
                style={(this.props.DEBUG) ? {
                  borderLeft: '2px solid red',
                  borderRight: '2px solid red',
                  opacity: 0.75,
                  pointerEvents: 'none',
                } : {}}
              >
                <Text
                  key={field.key}
                  label={label}
                  field={field}
                  value={val}
                  clearForm={this.state.clearForm}
                  hidden={true}
                  debug={this.props.DEBUG}
                />
              </div>
            )
          }

          if (field.type === 'hidden' && !this.props.DEBUG) {
            return (
              <Text
                key={field.key}
                label={label}
                field={field}
                value={val}
                clearForm={this.state.clearForm}
                hidden={true}
                debug={this.props.DEBUG}
              />
            )
          }

          let pendingChange = this.state.changes[field.key] || false;
          let pendingChangesClassName = 'pending-changes';
          if (!pendingChange) {
            pendingChangesClassName = '';
          }

          let requiredClassName = 'required';
          if (!field.required) {
            requiredClassName = '';
          }

          let fieldTypes = {
            'radio': Radio,
            'select': Select,
            'text': Text,
            'number': NumberField,
            'textarea': TextArea,
          };

          let fieldTypeKeys = Object.keys(fieldTypes);
          if (~fieldTypeKeys.indexOf(field.type)) {
            let Field = fieldTypes[field.type];

            return (
              <div className={`edit-form ${pendingChangesClassName} ${requiredClassName} field-${field.key} field-type-${field.type}`}
                key={field.key}
              >
                <Field
                  key={field.key}
                  form={this}
                  label={label}
                  labelStr={labelStr}
                  field={field}
                  value={val}
                  clearForm={this.state.clearForm}
                  data={this.props.selectData || []}
                  parent={this}
                  editIndex={this.state.editIndex}
                />
                <div className="helpText">
                  {field.helpText}
                </div>
              </div>
            )
          }

          return (
            <div className={`edit-form ${pendingChangesClassName} ${requiredClassName} field-${field.key} field-type-${field.type}`}
              key={field.key}
            >
              {field.type === 'date' &&
                // WARN: Date is already a JS object
                <DateField
                  key={field.key}
                  form={this}
                  label={label}
                  field={field}
                  value={val}
                  clearForm={this.state.clearForm}
                  parent={this}
                />
              }

              {field.type === 'time' &&
                <Time
                  key={field.key}
                  form={this}
                  label={label}
                  field={field}
                  value={val}
                  clearForm={this.state.clearForm}
                  parent={this}
                />
              }

              {field.type === 'img_upload_large' &&
                <ImgUpload
                  key={field.key}
                  form={this}
                  label={label}
                  field={field}
                  value={val}
                  clearForm={this.state.clearForm}
                  parent={this}
                />
              }

              {(field.type === 'img_upload' || field.type === 'img_and_pdf_upload') &&
                <ImgUpload
                  key={field.key}
                  form={this}
                  label={label}
                  field={field}
                  value={val}
                  clearForm={this.state.clearForm}
                  parent={this}
                />
              }

              {field.type === 'pdf_upload' &&
                <PDFUpload
                  key={field.key}
                  form={this}
                  label={label}
                  field={field}
                  value={val}
                  clearForm={this.state.clearForm}
                  miniMode={true}
                  parent={this}
                />
              }
            </div>
          )
        })}
      </form>
    )
  }
}

export default Form;