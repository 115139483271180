import React, { Component, Fragment } from 'react';

class Select extends Component {
  state = {
    idVal: this.props.value,
    value: this.props.value,
    style: {}
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    let propsData = this.props.data[this.props.field.dataKey];
    let idVal; 

    propsData.forEach((data) => {
      if (data.key === this.props.value) {
        idVal = data.id;
      }
    })

    if (prevProps.value !== this.props.value) {

      this.setState({
        idVal: idVal,
        value: this.props.value,
        style: {},
      });
    }

    if (prevProps.clearForm !== this.props.clearForm) {
      this.setState({
        idVal: '',
        value: this.props.value,
        style: {},
      });
    }
  }

  render() {
    let { label, field } = this.props;

    let propsData = this.props.data[field.dataKey];

    function dynamicSort(property) {
      var sortOrder = 1;

      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }

      return function (a, b) {
        if (sortOrder == -1) {
          return b[property].localeCompare(a[property]);
        } else {
          return a[property].localeCompare(b[property]);
        }
      }
    }

    propsData = propsData.sort(dynamicSort("val"));

    let invalidClassName = '';
    if (field.required && !this.state.value) {
      invalidClassName = 'invalid';
    }

    return (
      <Fragment>
        {!this.props.inline &&
          <label>{label}</label>
        }

        {(!this.props.inline && field.idKey) && 
          <input name={field.idKey} type="hidden" value={this.state.idVal} />
        }

        <select name={field.key}
          className={invalidClassName}
          style={this.state.style}
          value={this.state.value}
          onChange={(e) => {
            let val = e.target.value;

            let style = {};
            if (e.target.selectedIndex === 0 && field.required) {
              style = {
                border: '1px solid red',
              };
            }

            this.setState({
              value: val,
              style,
            });

            if (!this.props.inline && field.idKey) {
              try {
                let elm = document.getElementById(`field-${field.idKey}`);
                if (e.target.selectedIndex === 0) {
                  elm.value = '';
                } else {
                  // This is for create, won't change update
                  // elm.value = this.props.data[field.dataKey][(e.target.selectedIndex - 1)].id;
                  let nextIDVal = this.props.data[field.dataKey][(e.target.selectedIndex - 1)].id;

                  this.setState({
                    idVal: nextIDVal
                  })

                  // This is for update, won't change create
                  let nextSectionData = this.props.parent.props.parent.state.sectionData;
                  nextSectionData[this.props.editIndex][field.idKey] = this.props.data[field.dataKey][e.target.selectedIndex - 1].id;
                  this.props.parent.props.parent.setState({sectionData: nextSectionData});
                }
              } catch (err) {
                // console.warn(err);
              }
            }

            if (this.props.inline) {
              let formField = document.querySelector(`#${this.props.formId} [name="${field.key}"]`);
              formField.value = val;
              if (field.idKey) {
                let elm = document.getElementsByName(field.idKey)[0];
                if (e.target.selectedIndex === 0) {
                  elm.value = ''  
                } else {
                  elm.value = this.props.data[field.dataKey][(e.target.selectedIndex - 1)].id
                }
              }
            } else {
              this.props.form.onChange();

              if (field.required && (!val || val === '')) {
                e.target.classList.add('invalid');
                e.target.classList.remove('valid');
              }

              if (field.required && (val || val !== '')) {
                e.target.classList.remove('invalid');
                e.target.classList.add('valid');
              }
            }
          }}
        >
          <option value={''}>Select {this.props.labelStr}</option>
          {propsData.map((data, i) => {
            return (
              <option
                key={i}
                value={data.val}
              >{data.key}</option>
            )
          })}
        </select>
      </Fragment>
    )
  }
}

export default Select;