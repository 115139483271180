import React, { Component, Fragment } from 'react';
import {
  Link,
  Redirect,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import kali from 'kali';

import Header from '../component/Header';
import Loader from '../component/Loader';

import Content from '../component/dashboard/Content';
import Account from '../component/dashboard/Account';
import Support from '../component/dashboard/Support';
import Help from '../component/dashboard/Help';

const API_PREFIX = process.env.REACT_APP_API_PREFIX;

class Portal extends Component {
  constructor(props) {
    super(props);

    let propnums = [];
    try {
      propnums = JSON.parse(sessionStorage['propnums']);
    } catch (err) {
      propnums = [];
    }

    if (!propnums) {
      propnums = [];
    }

    this.state = {
      loading: this.props.loading || true,
      publishingChanges: this.props.publishingChanges || false,
      propnums,
      properties: {},
      usersData: this.props.usersData || {},
      searchFilter: '',
      selectedProperty: parseInt(sessionStorage['selected_property_number']) || false,
    };

    this.modes = [
      'PROPERTIES',
      'CONTENT',
      'ACCOUNT',
      'SUPPORT',
      'HELP',
    ];

    this.navLinks = [
      'CONTENT',
      'ACCOUNT',
      'SUPPORT',
      'HELP',
    ];

    if (sessionStorage['properties']) {
      let properties = [];
      try {
        properties = JSON.parse(sessionStorage['properties']);
      } catch (err) {
        // TODO: error handling
      }

      this.state.properties = properties;
      if (
        (Object.keys(this.state.properties).length !== 0) &&
        (Object.keys(this.state.usersData).length !== 0)
      ) {
        this.state.loading = false;
        this.state.publishingChanges = false;
      }
      return;
    }
  }

  componentDidMount() {
    if (Object.keys(this.state.properties).length === 0) {
      this.fetchPropertyNames();
    }

    if (Object.keys(this.state.usersData).length === 0) {
      this.fetchUsersData();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading,
      });
    }

    if (prevProps.publishingChanges !== this.props.publishingChanges) {
      this.setState({
        publishingChanges: this.props.publishingChanges,
      });
    }

    try {
      if (this.state.selectedProperty !== parseInt(this.props.usersData.prop_num)) {
        this.fetchUsersData();
      }
    } catch (err) { }
  }

  fetchPropertyNames() {
    let url = `https://${API_PREFIX}/legacy/propnums/`;
    let { headers } = this.props.app.signBroncoURL(url);
    new kali({
      headers,
      body: {
        propnums: this.state.propnums,
      },
    }).post(url, {
      success: (_kali, res, data) => {
        try {
          if (Object.keys(data).length === 0) {
            // if we have no properties go back to /login
            this.props.app.logout(true);

            return;
          }
        } catch (err) { }

        this.setState({
          loading: false,
          publishingChanges: false,
          properties: data,
        }, () => {
          sessionStorage['properties'] = JSON.stringify(data);
        });
      },

      failure: (_kali, err) => {
        console.error(err.statusText, err);
        toast.error(err.statusText, {
          position: toast.POSITION.TOP_RIGHT
        });
      },

      notAuthorized: (_kali, res, data) => {
        if (data.error) {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT
          });

          if (data.message === 'invalid session-key') {
            this.props.app.logout();
          }
        }
      }

      // TODO: specific error handling
    }, true);
  }

  fetchUsersData() {
    if (!this.state.selectedProperty) {
      return;
    }

    let ajaxRoute = 'updateUsersByProp';
    let { url } = this.props.app.signBroncoURL(`https:${API_PREFIX}/legacy/${ajaxRoute}/${this.state.selectedProperty}/`);
    new kali().get(url, {
      success: (_kali, res, data) => {
        let loading = this.state.loading;
        let publishingChanges = this.state.publishingChanges;
        if (this.state.properties) {
          loading = false;
          publishingChanges = false;
        }

        let now = new Date();
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);

        let expireDate = 0;
        if (data.data[0].online_expires) {
          expireDate = new Date(Date.parse(data.data[0].online_expires));
          expireDate.setHours(0);
          expireDate.setMinutes(0);
          expireDate.setSeconds(0);
        }

        let diff = Math.round((expireDate - now) / 1000 / 60 / 60 / 24);
        if (diff > 90) {
          diff = false;
        }

        // if (diff < 1) {
        //   data.data[0].archive = '1'
        // }

        // if (diff === false) {
        //   data.data[0].archive = '0'
        // }

        this.setState({
          loading,
          publishingChanges,
          usersData: data.data[0],
        });
      },

      failure: (_kali, err) => {
        toast.error(err.statusText, {
          position: toast.POSITION.TOP_RIGHT
        });
      },
    });
  }

  setSelectedPropertyNumber(selectedProperty) {
    sessionStorage['selected_property_number'] = selectedProperty;
    let l = window.location;
    return window.location.href = `${l.protocol}//${l.host}/dashboard/content/companies`;

    // this.setState({
    //   selectedProperty,
    //   section: this.props.section,
    // });

    // this.fetchUsersData();
  }

  renderFilterablePropertySelector() {
    if (this.state.selectedProperty) {
      // return;
    }

    if (this.state.loading) {
      return;
    }

    if (this.state.publishingChanges) {
      return;
    }

    return (
      <Fragment>
        <div className="login-container properties-container">
          <div className="properties-header">
            <div className="properties-logo">
              <img src="/assets/images/login-logo.png" alt="logo" />
            </div>
            <div className="properties-text">
              <p>Search by Properties Names</p>
            </div>
            <div className="properties-list-search">
              <input
                type="text"
                name="searchValue"
                onChange={(e) => {
                  this.setState({
                    searchFilter: e.target.value,
                  });
                }}
                value={this.state.searchFilter}
                placeholder="search"
              /> <span><i className="fa fa-search" aria-hidden="true"></i></span>
            </div>
          </div>
          <div className="properties">
            <div className="properties-list">
              <ul className="nicescroll">
                {this.state.propnums.map((n) => {
                  let name;
                  try {
                    name = this.state.properties[n] || n.toString();
                  } catch (err) {
                    name = n.toString();
                  }
                  if (!~name.toLowerCase().indexOf(this.state.searchFilter.toLowerCase())) {
                    return false;
                  }

                  return (
                    <li key={n}>
                      <Link
                        to="/dashboard/content/companies"
                        onClick={() => {
                          this.setSelectedPropertyNumber(n);
                        }}
                      >{name}</Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  render() {
    let mode = 'CONTENT';
    try {
      mode = this.props.mode.toUpperCase();
    } catch (err) {
      // TODO: error handling
    }

    if (!~this.modes.indexOf(mode)) {
      return (
        <Redirect
          push={false}
          to='/dashboard/content/companies'
        />
      )
    }

    if (mode === 'CONTENT') {
      if (this.state.usersData.archive === '1') {
        return (
          <Redirect
            replace
            to='/dashboard/account/renew'
          />
        )
      }
    }

    return (
      <div>
        {this.state.loading &&
          <Loader customLoader={false} />
        }

        {this.state.publishingChanges &&
          <Loader customLoader={true} loadingTitle="Publishing your changes" loadingMessage="Systems will update their content overnight." email={this.state.usersData.updating_email} />
        }

        <ToastContainer autoClose={5000} />

        <Header
          app={this.props.app}
          portal={this}
          portalMode={mode}
          selectedProperty={this.state.selectedProperty}
          usersData={this.state.usersData || {}}
        />

        {mode === 'PROPERTIES' &&
          this.renderFilterablePropertySelector()
        }

        {mode === 'CONTENT' &&
          <Content
            app={this.props.app}
            portal={this}
            portalMode={this.props.mode}
            portalSection={this.props.section}
            selectedProperty={this.state.selectedProperty}
            usersData={this.state.usersData || {}}
            id="contentClick"
          />
        }

        {mode === 'ACCOUNT' &&
          <Account
            app={this.props.app}
            portal={this}
            portalMode={this.props.mode}
            portalSection={this.props.section}
            selectedProperty={this.state.selectedProperty}
            usersData={this.state.usersData || {}}
            id="accountClick"
          />
        }

        {mode === 'SUPPORT' &&
          <Support
            app={this.props.app}
            portal={this}
            portalMode={this.props.mode}
            portalSection={this.props.section}
            selectedProperty={this.state.selectedProperty}
            usersData={this.state.usersData || {}}
            id="supportClick"
          />
        }

        {mode === 'HELP' &&
          <Help
            app={this.props.app}
            portal={this}
            portalMode={this.props.mode}
            portalSection={this.props.section}
            selectedProperty={this.state.selectedProperty}
            usersData={this.state.usersData || {}}
            id="helpClick"
          />
        }
      </div>
    );
  }
}

export default Portal;
