import React, { Component, Fragment } from "react";
import { NavLink, Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import kali from "kali";

import Form, {
  buildSelectData,
  getFormFieldsStatus,
  getFormFieldChanges,
} from "../../form/Form.js";

import { getSectionFieldsByFormName } from "../../data/fieldData.js";

import { formatDateShort } from "../../form/field/Date.js";

let navData = [
  {
    defaultLabel: "Management Contact Info",
    route: "managementContactInfo",
    id: "managementContactInfoClick",
    ajaxRoute: "updateUsers",
  },
  {
    defaultLabel: "Edit Billing",
    route: "editBilling",
    id: "editBillingClick",
    ajaxRoute: "editBilling",
  },
  {
    defaultLabel: "Request A Change",
    route: "requestAChange",
    id: "requestChangeClick",
    ajaxRoute: "updateUsers",
  },
];

let resellerNavData = [
  {
    defaultLabel: "Management Contact Info",
    route: "managementContactInfo",
    id: "managementContactInfoClick",
    ajaxRoute: "updateUsers",
  },
  {
    defaultLabel: "Edit Billing",
    route: "editBilling",
    id: "editBillingClick",
    ajaxRoute: "editBilling",
  },
  {
    defaultLabel: "Request A Change",
    route: "requestAChange",
    id: "requestChangeClick",
    ajaxRoute: "updateUsers",
  },
];

let expiredNavData =  [
  {
    defaultLabel: "Management Contact Info",
    route: "managementContactInfo",
    id: "managementContactInfoClick",
    ajaxRoute: "updateUsers",
  },
  {
    defaultLabel: "Edit Billing",
    route: "editBilling",
    id: "editBillingClick",
    ajaxRoute: "editBilling",
  },
  {
    defaultLabel: "Request A Change",
    route: "requestAChange",
    id: "requestChangeClick",
    ajaxRoute: "updateUsers",
  },
]


const API_PREFIX = process.env.REACT_APP_API_PREFIX;
const thankYouUrl =
  window.location.protocol +
  `//` +
  window.location.host +
  `/dashboard/thankyou`;

// Get IE or Edge browser version
let version = detectIE();

let internetExplorer = false;

if (version === false) {
  console.log("Browser is Not IE or Edge");
} else if (version >= 12) {
  console.log("Browser is Edge");
} else {
  console.log("Browser is Internet Explorer");
  internetExplorer = true;
}

function detectIE() {
  let ua = window.navigator.userAgent;

  let msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
  }

  let trident = ua.indexOf("Trident/");
  if (trident > 0) {
    // IE 11 => return version number
    let rv = ua.indexOf("rv:");
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
  }

  let edge = ua.indexOf("Edge/");
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
  }

  // other browser
  return false;
}

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      section: this.props.portalSection,
      contentData: {},
      sectionData: [],
      editIndex: 0,
      selectedProperty:
        parseInt(sessionStorage["selected_property_number"]) || false,
      usersData: this.props.usersData || {},
      showPrintOption: true,
      modalError: false,
      modalSuccess: false,
      modalErrorMessage: "",
      modalSuccessMessage: "",
    };

    if (this.props.portalSection === "renew") {
      this.state.pendingChanges = true;
    }
  }

  print() {
    window.print();
  }

  componentDidMount() {
    let sectionData = [];
    sectionData.push(this.props.usersData || {});

    this.setState({
      sectionData,
      usersData: this.props.usersData,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    let expiredAccountDate = new Date("Fri Jan 01 00:00:01 EST 2021")
    let currentAccountDate = new Date(this.props.usersData.online_expires)

    if (prevProps.usersData !== this.props.usersData) {
      let sectionData = [];
      sectionData.push(this.props.usersData || {});

      this.setState({
        sectionData,
        usersData: this.props.usersData,
      });
    }
    //If a reseller account dont display Renew Account nav button
    if (
      this.props.usersData.reseller == "RR" ||
      this.props.usersData.reseller == "Direct Billing to Reseller"
    ) {
      navData = resellerNavData;
    }
    // If the accounts expire date is after 2020 we no longer want to present the Renew Account nav button
    if (currentAccountDate >= expiredAccountDate){
      navData = expiredNavData
    }

    if (prevProps.portalSection !== this.props.portalSection) {
      if (this.props.portalSection === "renew") {
        this.setState({
          invoice: false,
          pendingChanges: true,
        });
      } else {
        this.setState({
          invoice: false,
          pendingChanges: false,
        });
      }
    }
  }

  onRequestAChange(formData) {
    formData.append("to", "revisions@touchsource.com");
    formData.append("from", formData.get("email"));

    let url = `https:${API_PREFIX}/legacy/emailRequest/`;
    let { headers } = this.props.app.signBroncoURL(url);
    console.log(url);
    new kali({
      headers,
      form: formData,
    }).post(url, {
      success: (_kali, res, data) => {
        // TODO: something to let them know it sent

        this.setState(
          {
            pendingChanges: false,
            modalSuccess: true,
            modalSuccessMessage: "Your request has been sent.",
            modalError: false,
            modalErrorMessage: "",
          },
          (e) => {
            // HACK: make it look like it did something
            window.setTimeout(() => {
              this.props.portal.setState({
                loading: false,
              });
            }, 500);
          }
        );
        console.log("created/updated");
      },

      failure: (_kali, err) => {
        // HACK: make it look like it did something
        window.setTimeout(() => {
          this.props.portal.setState(
            {
              loading: false,
            },
            () => {
              if (err && err.statusText) {
                toast.error(err.statusText, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          );
        }, 500);
      },

      serverError: (_kali, res, err) => {
        // HACK: make it look like it did something
        window.setTimeout(() => {
          this.props.portal.setState(
            {
              loading: false,
            },
            () => {
              if (err && err.error) {
                toast.error(err.error, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          );
        }, 500);
      },
    });
  }

  onFormSave(formName, e) {
    let { portal } = this.props;
    let { mode, section } = portal.props;

    let id = `form-${mode}-${section}`;
    if (formName) {
      id = `form-${formName}`;
    }
    let form = document.getElementById(id);
    let formData = new FormData(form);

    let { contentData, sectionData } = this.state;
    let orgData = sectionData[this.state.editIndex];
    let sectionFields = getSectionFieldsByFormName(
      formName,
      section,
      contentData
    );

    let { valid, fields } = getFormFieldsStatus(
      mode,
      section,
      sectionFields,
      formName
    );
    if (!valid) {
      this.setState({
        modalError: true,
        modalErrorMessage: "Please enter all mandatory fields",
        modalSuccess: false,
        modalSuccessMessage: "",
      });

      return;
    }

    if (section === "requestAChange") {
      return this.onRequestAChange(formData);
    }
    let salesforceSubmitButton 

    if (section === "editBilling"){
      // This will also send billing information to salesforce
      console.log(" in edit billing section")
      console.log(this.props)
  
  
      let salesForceMessage = `
      This case was submitted by a customer from the from the customer portal. 
      Please update the billing contact information in salesforce to the following: 
  
      Property Number: ${this.props.selectedProperty}
  
      Billing Name: ${formData.get('billing_contact')}
      Billing E-Mail Address: ${formData.get('billing_email')}
      Billing Company Name: ${formData.get('billing_company')}
      Billing Address-1: ${formData.get('billing_address_1')}
      Billing Address-2: ${formData.get('billing_address_2')}
      Billing City: ${formData.get('billing_city')}
      Billing State: ${formData.get('billing_state')}
      Billing Postal: ${formData.get('billing_postal')}
      Billing Phone: ${formData.get('billing_phone')}
      Billing Fax: ${formData.get('billing_fax')}
      `
      let url = "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8";
      let subjectStr = `Update Billing Info for ${this.props.selectedProperty}`;
      console.log(salesForceMessage);
  
      const thankYouReturnURL =
            window.location.protocol +
            `//` +
            window.location.host +
            `/dashboard/thankyou`;
  
      let salesForceHiddenForm = document.createElement('form');
      salesForceHiddenForm.method = 'POST';
      salesForceHiddenForm.action = url;
      salesForceHiddenForm.type = 'hidden';    
      
      const originInput = document.createElement('input'); 
      originInput.name = 'orgid';
      originInput.value = '00Dj0000001rkAe';
      originInput.type ='hidden'
  
      const recordTypeInput = document.createElement('input');
      recordTypeInput.name  = 'recordType';
      recordTypeInput.value = '0123Z000000qi3MQAQ';
      recordTypeInput.type ='hidden'
  
      const returnURLInput = document.createElement('input');
      returnURLInput.name = 'retURL';
      returnURLInput.value= 'https://portal.touchsource.com/dashboard/thankyou';
      returnURLInput.type ='hidden'
        
      const subjectInput = document.createElement('input');
      subjectInput.name = 'subject';
      subjectInput.value = subjectStr;
      subjectInput.type ='hidden'
      
      const accountNameInput = document.createElement('input');
      accountNameInput.name = 'accountName';
      accountNameInput.value = this.props.selectedProperty;
      accountNameInput.type = 'hidden'
  
      const salesForceAccountEmail = document.createElement('input');
      salesForceAccountEmail.name = "email";
      salesForceAccountEmail.value = this.props.usersData.mgmt_email;
      salesForceAccountEmail.type ='hidden'
      
      const descriptionTextArea = document.createElement('textarea');
      descriptionTextArea.for = 'description';
      descriptionTextArea.name = 'description';
      descriptionTextArea.value = salesForceMessage;
      descriptionTextArea.style = 'display:none'

      salesforceSubmitButton = document.createElement('input');
      salesforceSubmitButton.type = "submit";
      salesforceSubmitButton.style = 'display:none'
      
        
      salesForceHiddenForm.appendChild(originInput);
      salesForceHiddenForm.appendChild(subjectInput);
      salesForceHiddenForm.appendChild(returnURLInput);
      salesForceHiddenForm.appendChild(accountNameInput);
      salesForceHiddenForm.appendChild(descriptionTextArea);
      salesForceHiddenForm.appendChild(salesForceAccountEmail);
      salesForceHiddenForm.appendChild(recordTypeInput);
      salesForceHiddenForm.appendChild(salesforceSubmitButton);
        
      document.body.appendChild(salesForceHiddenForm);
  
      // for testing purposes
      console.log("****   saleforce form  ****** ");
      console.log(salesForceHiddenForm);
      console.log(salesForceMessage);
      console.log(thankYouReturnURL);
  
      setTimeout(function(){ 
      
      //  Add a timeout to allow atlas to update before submittiting information to Salesforce
      }, 1500);    
    }
  
    let pendingChanges = getFormFieldChanges(
      mode,
      section,
      sectionFields,
      orgData,
      formName
    );
    if (Object.keys(pendingChanges).length === 0) {
      if (section === "renew") {
        this.setState({
          invoice: true,
        });
      }

      return;
    }

    this.setState({
      modalError: false,
      modalErrorMessage: "",
      modalSuccess: false,
      modalSuccessMessage: "",
    });

    let jsonFormData = {};
    let newData = {};
    for (let d of formData.entries()) {
      newData[d[0]] = d[1];
      jsonFormData[d[0]] = d[1];
    }
    jsonFormData["ID"] = parseInt(jsonFormData["ID"]);

    this.props.portal.setState({
      loading: true,
    });

    let ajaxRoute = "updateUsers";
    let url = `https:${API_PREFIX}/legacy/${ajaxRoute}/`;
    let { headers } = this.props.app.signBroncoURL(url);
    console.log(url);
    new kali({
      headers,
      body: jsonFormData,
    }).post(url, {
      success: (_kali, res, data) => {
        let { sectionData } = this.state;
        sectionData[this.state.editIndex] = newData;
        this.props.portal.setState({
          usersData: sectionData[0],
        });

        if (section === "renew") {
          this.setState({
            invoice: true,
          });
        
        }


        this.setState(
          {
            pendingChanges: false,
          },
          (e) => {
            // HACK: make it look like it did something
            window.setTimeout(() => {
              this.props.portal.setState({
                loading: false,
              });
            }, 500);
          }
        );
        if (section === "editBilling"){
          salesforceSubmitButton.click();
        }
      },

      failure: (_kali, err) => {
        // HACK: make it look like it did something
        window.setTimeout(() => {
          this.props.portal.setState(
            {
              loading: false,
            },
            () => {
              if (err && err.statusText) {
                toast.error(err.statusText, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          );
        }, 500);
      },

      serverError: (_kali, res, err) => {
        // HACK: make it look like it did something
        window.setTimeout(() => {
          this.props.portal.setState(
            {
              loading: false,
            },
            () => {
              let errMessage;
              if (err && err.error) {
                errMessage = err.error;
              }

              if (
                err &&
                err.data &&
                ~err.data.Message.indexOf("Incorrect string value")
              ) {
                errMessage =
                  "Invalid character(s) in text field and/or file name.";
              }

              if (errMessage) {
                toast.error(errMessage, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          );
        }, 500);
      },
    });
  
  }

  renderForm(mode, section) {
    let { invoice } = this.state;

    if (invoice) {
      return;
    }

    let { sectionData } = this.state;
    if (!sectionData || sectionData.length === 0) {
      return;
    }

    // formatDate(this.state.online_expires)
    let accountExpiration =
      sectionData[this.state.editIndex]["online_expires"] || "online_expires";
    accountExpiration = formatDateShort(accountExpiration);

    let d = new Date(Date.parse(accountExpiration));
    d.setFullYear(d.getFullYear() + 1);
    let expireDate = formatDateShort(d.toString());

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    let webNewsFee = sectionData[this.state.editIndex]["web_news_fee"] || 0;
    let renderWebNewsFee = formatter.format(webNewsFee);
    let onlineFee = sectionData[this.state.editIndex]["online_fee"] || 0;
    let renderOnlineFee = formatter.format(onlineFee);
    let warrantyFee = sectionData[this.state.editIndex]["warranty_fee"] || 0;
    let renderWarrantyFee = formatter.format(warrantyFee);
    let otherFee = sectionData[this.state.editIndex]["other_fee"] || 0;
    let renderOtherFee = formatter.format(otherFee);
    let totalFee = (
      parseFloat(webNewsFee) +
      parseFloat(onlineFee) +
      parseFloat(warrantyFee) +
      parseFloat(otherFee)
    ).toFixed(2);

    let formName;
    switch (section) {
      case "managementContactInfo":
        formName = "account::management_contact_info";
        break;

      case "editBilling":
        formName = "account::edit_billing";
        break;

      case "renew":
        formName = "account::edit_billing";
        break;

      case "requestAChange":
        break;

      default:
        console.warn(section, "is missing?");
    }

    let title;
    for (let data of navData) {
      if (data.route !== section) {
        continue;
      }

      title = data.defaultLabel;
    }

    let gaId = "";

    if (section === "managementContactInfo") {
      gaId = "managementContactInfoSubmit";
    }
    if (section === "editBilling") {
      gaId = "editBillingSubmit";
    }
    if (section === "requestAChange") {
      gaId = "requestChangeSubmit";
    }

    return (
      <Fragment>
        <div className="text-label-info-left full-width-div">
          <h1>{title}</h1>
          <p class="mandatory">* Required Fields</p>

          {section !== "renew" && section !== "requestAChange" && (
            <div class="mandatory-subtitle">
              <span>Click the Submit button below when done.</span>
            </div>
          )}

          {section === "requestAChange" && (
            <div class="mandatory-subtitle">
              <span>
                Please enter the following information, being as specific as
                possible on your request. One of our team members will respond
                within the next 24 hours.
              </span>
            </div>
          )}

          {this.state.modalError && (
            <div className="left-float error-msg">
              {this.state.modalErrorMessage}
            </div>
          )}

          {this.state.modalSuccess && (
            <div className="left-float success-msg">
              {this.state.modalSuccessMessage}
            </div>
          )}

          {section !== "requestAChange" && (
            <Form
              parent={this}
              contentData={this.state.contentData}
              editIndex={this.state.editIndex}
              mode={mode}
              section={section}
              formName={formName}
              sectionData={this.state.sectionData}
              selectData={buildSelectData()}
            />
          )}

          {section === "managementContactInfo" && (
            <div className="form-submit">
              <input
                className={`btn save ${
                  !this.state.pendingChanges ? "" : "pending-changes"
                }`}
                type="button"
                value="Submit"
                onClick={this.onFormSave.bind(this, formName)}
                id={gaId}
              />
            </div>
          )}

          {section === "editBilling" && (
            <div className="form-submit">
              <input
                className={`btn save ${
                  !this.state.pendingChanges ? "" : "pending-changes"
                }`}
                type="button"
                value="Submit"
                onClick={this.onFormSave.bind(this, formName)}
                id={gaId}
              />
            </div>
          )}

          {section === "requestAChange" && (
            <Fragment>
              <form
                action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
                method="POST"
                className="formTS"
              >
                <input type="hidden" name="orgid" value="00Dj0000001rkAe" />
                <input type="hidden" name="retURL" value={thankYouUrl} />
                <input
                  type="hidden"
                  name="subject"
                  value={this.props.selectedProperty}
                />

                <div className="mandatory-subtitle"></div>

                <input
                  id="subject"
                  maxlength="80"
                  name="subject"
                  size="20"
                  type="hidden"
                />
                <div className="edit-form">
                  <label className="support-form__label" for="name">
                    <span>* </span>Name
                  </label>
                  <input
                    required
                    autocomplete="off"
                    id="name"
                    maxlength="80"
                    name="name"
                    size="20"
                    type="text"
                  />
                  <br></br>
                </div>

                <div className="edit-form">
                  <label className="support-form__label" for="email">
                    <span>* </span>Email
                  </label>
                  <input
                    required
                    autocomplete="off"
                    id="email"
                    maxlength="80"
                    name="email"
                    size="20"
                    type="text"
                  />
                  <br></br>
                </div>

                <div className="edit-form">
                  <label className="support-form__label" for="phone">
                    <span>* </span>Phone
                  </label>
                  <input
                    required
                    autocomplete="off"
                    id="phone"
                    maxlength="40"
                    name="phone"
                    size="20"
                    type="text"
                  />
                  <br></br>
                </div>

                <div className="edit-form">
                  <label className="support-form__label" for="description">
                    <span>* </span>What is the address of your property
                  </label>
                  <textarea
                    required
                    autocomplete="off"
                    name="address"
                  ></textarea>
                  <br></br>
                </div>

                <div className="edit-form">
                  <label className="support-form__label" for="phone">
                    Select your support issue
                  </label>
                  <select
                    id="00N3Z00000CJqPm"
                    title="Support Form Issue"
                    name="00N3Z00000CJqPm"
                    style={{ width: "52%" }}
                  >
                    <option value="My TouchSource system is not updating">
                      My TouchSource system is not updating
                    </option>
                    <option value="The screen on my TouchSource system is not functioning properly">
                      The screen on my TouchSource system is not functioning
                      properly
                    </option>
                    <option value="I would like to change the graphics, design or functionality on my TouchSource program">
                      I would like to change the graphics, design or
                      functionality on my TouchSource program
                    </option>
                    <option value="I am having issues connecting to the portal">
                      I am having issues connecting to the portal
                    </option>
                    <option value="I have a question about billing">
                      I have a question about billing
                    </option>
                    <option value="I would like to reset my password">
                      I would like to reset my password
                    </option>
                    <option value="My support issue is not listed">
                      My support issue is not listed
                    </option>
                  </select>
                  <br></br>
                </div>

                <div className="edit-form">
                  <label className="support-form__label" for="description">
                    <span> </span>Additional information
                  </label>
                  <textarea
                    required
                    autocomplete="off"
                    name="description"
                  ></textarea>
                  <br></br>
                </div>

                <button className="btn" type="submit" name="submit">
                  Submit
                </button>
              </form>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }


  render() {
    if (
      this.state.redirect &&
      window.location.pathname !== this.state.redirectTo
    ) {
      return <Redirect push={false} to={this.state.redirectTo} />;
    }

    let { portal } = this.props;
    let { mode, section } = portal.props;

    const submitChangesButton = () => {
      if (this.state.usersData.update_type == "W") {
        return (
          <div className="publish-button">
            <div className="tooltip tooltip-left tooltip-wide tooltip-dark">
              <i
                className="fa fa-question-circle"
                onMouseOver={() => {
                  this.setState({
                    publishTooltipActive: true,
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    publishTooltipActive: false,
                  });
                }}
              ></i>
              {this.state.publishTooltipActive && (
                <div className="tooltip-message">
                  Content will be updated and downloaded.
                  <div className="tooltip-triangle"></div>
                </div>
              )}
            </div>
            <button
              type="button"
              className="submitChangesButton btn right-float"
              onClick={() => {
                this.props.app.fetchBarbarian(this.state.usersData.update_type);
              }}
            >
              <i class="fa fa-sign-out"></i> Publish Changes and Download
            </button>
          </div>
        );
      }
      if (this.props.portalSection === "slideshow") {
        if (
          this.state.contentData.video_upload === "1" &&
          this.state.contentData.multiple_bldg === "1"
        ) {
          return (
            <div className="publish-button">
              <div className="tooltip tooltip-left tooltip-wide tooltip-dark">
                <i
                  className="fa fa-question-circle"
                  onMouseOver={() => {
                    this.setState({
                      publishTooltipActive: true,
                    });
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      publishTooltipActive: false,
                    });
                  }}
                ></i>
                {this.state.publishTooltipActive && (
                  <div className="tooltip-message">
                    Systems will update slideshow content immediately.
                    <div className="tooltip-triangle"></div>
                  </div>
                )}
              </div>
              <button
                type="button"
                className="submitChangesButton btn right-float disabled"
              >
                <i class="fa fa-image"></i> Changes Made Live
              </button>
            </div>
          );
        }
      }
      if (this.props.portalSection === "slideshow") {
        if (
          this.state.contentData.video_upload !== "1" ||
          this.state.contentData.multiple_bldg !== "1"
        ) {
          return (
            <div className="publish-button">
              <div className="tooltip tooltip-left tooltip-wide tooltip-dark">
                <i
                  className="fa fa-question-circle"
                  onMouseOver={() => {
                    this.setState({
                      publishTooltipActive: true,
                    });
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      publishTooltipActive: false,
                    });
                  }}
                ></i>
                {this.state.publishTooltipActive && (
                  <div className="tooltip-message">
                    Systems will update their content overnight.
                    <div className="tooltip-triangle"></div>
                  </div>
                )}
              </div>
              <button
                type="button"
                className="submitChangesButton btn right-float"
                onClick={() => {
                  this.props.app.fetchBarbarian(
                    this.state.usersData.update_type
                  );
                }}
              >
                <i class="fa fa-sign-out"></i> Publish Changes
              </button>
            </div>
          );
        }
      } else {
        return (
          <div className="publish-button">
            <div className="tooltip tooltip-left tooltip-wide tooltip-dark">
              <i
                className="fa fa-question-circle"
                onMouseOver={() => {
                  this.setState({
                    publishTooltipActive: true,
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    publishTooltipActive: false,
                  });
                }}
              ></i>
              {this.state.publishTooltipActive && (
                <div className="tooltip-message">
                  Systems will update their content overnight.
                  <div className="tooltip-triangle"></div>
                </div>
              )}
            </div>
            <button
              type="button"
              className="submitChangesButton btn right-float"
              onClick={() => {
                this.props.app.fetchBarbarian(this.state.usersData.update_type);
              }}
            >
              <i class="fa fa-sign-out"></i> Publish Changes
            </button>
          </div>
        );
      }
    };

    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    let expireDate = 0;
    if (this.state.usersData.online_expires) {
      expireDate = new Date(Date.parse(this.state.usersData.online_expires));
      expireDate.setHours(0);
      expireDate.setMinutes(0);
      expireDate.setSeconds(0);
    }

    let diff = Math.round((expireDate - now) / 1000 / 60 / 60 / 24);
    if (diff < 1 || diff > 90) {
      diff = false;
    }

    let expireDateStr = '';
    if (diff) {
      let year = expireDate.getFullYear();
      let month = (1 + expireDate.getMonth()).toString().padStart(2, '0');
      let day = expireDate.getDate().toString().padStart(2, '0');
      expireDateStr = month + '/' + day + '/' + year;
    }

    return (
      <Fragment>
        <div className="header-bottom">
          {internetExplorer && (
            <div className="ie-message">
              <span>We noticed you are using Internet Explorer.</span>
              To have the best portal experience, please switch to the{" "}
              <a target="_blank" href="https://www.google.com/chrome/">
                Chrome web browser
              </a>
              .
            </div>
          )}

          {this.state.usersData.online_expires &&
            this.state.usersData.reseller === "" && (
              <div className="renew-message">
                {diff && (
                  <Fragment>
                    <span className="renew-icon message-icon"></span>
                    Subscription expires {expireDateStr} – invoice will be provided 60 days prior to due date. Please click 'Account' above to ensure that billing information is correct. Please email renewals@touchsource.com with additional questions.
                  </Fragment>
                )}
              </div>
            )}

          {this.state.usersData.archive === "1" && (
            <div className="renew-message">
              <span className="renew-icon message-icon"></span> Your subscription has expired. To renew your account please call (303) 828-4007 (ext 3) or email renewals@touchsource.com
            </div>
          )}

          {submitChangesButton()}
        </div>

        <div className={`content-body ${mode} ${section}`}>
          <div className="left-navigation">
            <select
              value={section}
              onChange={(e) => {
                this.setState({
                  redirect: true,
                  redirectTo: `/dashboard/account/${e.target.value}`,
                });
              }}
            >
              {navData.map((data) => {
                if (!data) {
                  return false;
                }

                return (
                  <option key={data.route} value={data.route}>
                    {this.state.contentData[data.labelKey] || data.defaultLabel}
                  </option>
                );
              })}
            </select>

            <ul>
              {navData.map((data) => {
                if (!data) {
                  return false;
                }

                let activeClassName = "active";
                if (section !== data.route) {
                  activeClassName = "";
                }

                if (internetExplorer === true) {
                  return (
                    <li
                      key={data.route}
                      className={activeClassName}
                      id={data.id}
                    >
                      <NavLink
                        to={`/dashboard/account/${data.route}`}
                        activeClassName="active"
                        id={data.id}
                      >
                        {this.state.contentData[data.labelKey] ||
                          data.defaultLabel}
                      </NavLink>
                    </li>
                  );
                }

                return (
                  <li key={data.route} className={activeClassName}>
                    <NavLink
                      to={`/dashboard/account/${data.route}`}
                      activeClassName="active"
                    >
                      {this.state.contentData[data.labelKey] ||
                        data.defaultLabel}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className={`content-section ${mode} ${section}`}>
            {this.renderForm(mode, section)}

            {this.props.DEBUG && (
              <div className="debug-raw-data-box">
                <pre>
                  <code>
                    COUNT: {this.state.sectionData.length + "\n"}
                    {JSON.stringify(this.state.sectionData, null, 2)}
                  </code>
                </pre>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Account;
