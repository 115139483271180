import React from 'react';

class Loader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }
  
  render() {

    let emailMessage = '';

    if (this.props.customLoader === true) {

      let email = <span className="loader-email"><i class="fas fa-user"></i> {this.props.email}</span>

      if (this.props.email === '') {
        email = <span className="loader-error"><i class="fas fa-exclamation-circle"></i> Please add an updating contact email</span>
      }

      emailMessage = <p>If address above is not correct, click on the Account<br/> Tab &rarr; Management Contact Info &rarr; <strong>Updating<br/> Contact Email</strong> field.</p>

      console.log(this.props.email);

    }

    return (
      <div>
        <div className="loader-opacity" />
        <div className="loader_overlay">
          <div className="loader_wrap">

            <div class="loader loader-bars">
              <span></span>	
            </div>

            <div class="custom-loader-message-container">
              <h2>{this.props.loadingTitle}</h2>
              <h3>{this.props.loadingMessage}</h3>
              {emailMessage}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Loader;